import React ,{useState,useEffect} from "react";
import { Link } from "react-router-dom";

import LandingBackground from "../assets/Svg/LandingPageBck.svg";
import ContactUsSvg from "../assets/Svg/contactwhat.svg";
import InsuranceCard from "../UI/InsuranceCard";
import CarFrame from "../assets/Svg/CarFrameIns.svg";
import BikeFrame from "../assets/Svg/BikeFrameIns.svg";
import HealthFrame from "../assets/Svg/HealthFrameIns.svg";
import TeamFrame from "../assets/Svg/TeamFrameIns.svg";
import WhoareWeSvg from "../assets/Svg/whoareweSvg.svg";
import DotBck from "../assets/Svg/dotsBck.jpg";
import Vector1 from "../assets/Vector/Vector1.svg";
import WhatsAppSvg from "../assets/Vector/WhatsappSvg.svg";
import Footer from "../components/Footer";
import Testimonial from "../components/slider/Testimonial";
import Article from "../components/Article";
import Partners from "../components/slider/Partners";
import Faq from "../components/Faq";
import NavHeader from "../components/NavHeader";
import ReactGA from "react-ga4";


const whatWeOffer = [
  "Competitive prices",
  "Best coverage",
  "Claims support",
  "Quote Comparison",
  "Insurance consultation provided"
];

const HomeLanding = () => {
const [closeNav, setCloseNav] = useState(false)

ReactGA.initialize("G-3CEV86FDMQ");

// for Google Analytics 

useEffect(()=>{
  //non interaction Event

  ReactGA.send({
    hitType: "HomePage",
    page: window.location.pathname,
    title: "Main Home Page"
  });
},[])

const handleAnalytics = (val,type)=>{



 ReactGA.event({
  category:val,
  action:{type},
  label:val,
 })
}

  return (
    <>
      <NavHeader dropClose={ [closeNav, setCloseNav] } />
      <main
        className="w-full bg-[rgba(0,167,142,0.1)] lg:bg-none   bg-no-repeat bg-cover  "
        //   style="background-image:url('../assets/FooterSvg/irdaLogo.png);"
        style={{ backgroundImage: `url(${LandingBackground})` }}  onClick={()=>{
          setCloseNav(false)
        }}
      >
        {/* Section - 1 */}
        <section className="flex flex-col lg:flex-row items-center justify-between px-3 lg:px-32 py-7 lg:py-24">
          <div className="space-y-5">
            <h1 className="text-2xl font-inter  font-bold text-center lg:text-start lg:text-5xl text-[#1f243c] lg:leading-snug ">
              Affordable <br /> Insurance Plans <br />{" "}
              <span className="text-[#00A78E] font-semibold">
                {" "}
                Just for you
              </span>
            </h1>
            <div className="pt-3 pb-8 lg:py-0  w-max">
              <Link to="/contactus">
                <img
                  src={ContactUsSvg}
                  alt="ContactUsSvg"
                  className="pt-3 hidden lg:block pb-8 lg:py-0"
                />
              </Link>
              <a href="tel:7014987001" title="">
                <img src={ContactUsSvg} alt="ContactUsSvg" className=" lg:hidden " />
              </a>
            </div>
          </div>
          <div
            className="grid grid-cols-2 lg:flex gap-4 lg:gap-0  gap-y-5 lg:space-x-7 bg-repeat bg-cover lg:p-20 lg:-m-20 "
            style={{ backgroundImage: `url(${DotBck})` }}
          >
            <Link onClick={handleAnalytics('Car Insurance','Car Ins Button HP')} to="/carinsurance/car-profile/prestep-1">
              {" "}
              <InsuranceCard ImgUrl={CarFrame} InsName="Car Insurance" />
            </Link>
            <Link onClick={handleAnalytics('Bike Insurance','Bike Ins Button HP')} to="/bikeinsurance/bike-profile/prestep-1">
              <InsuranceCard ImgUrl={BikeFrame} InsName="Bike Insurance" />
            </Link>
            <Link to={window.location.hostname==='www.policymart.co.in'? "/healthInsurance": "/healthinsurance/health-profile/prestep-1"}>
              <InsuranceCard ImgUrl={HealthFrame} InsName="Health Insurance" />
            </Link>
            <Link to={window.location.hostname==='www.policymart.co.in'? "/termInsurance": "/terminsurance/term-profile/prestep-1"}  >
              <InsuranceCard ImgUrl={TeamFrame} InsName="Term Insurance" />
            </Link>
          </div>
        </section>

        <div className="">
          <a href=" https://wa.me/917014987001" target="_blank">
            <img
              src={WhatsAppSvg}
              alt="WhatsAppSvg"
              className="hidden lg:block absolute right-0"
            />
          </a>
        </div>

        {/* Section -2 (Who are We) */}

        <section className="flex flex-col lg:flex-row py-6 lg:py-24 px-6 lg:px-32 items-center  ">
          <div className="sec2Anim1">
            <img src={WhoareWeSvg} alt="WhoareWeSvg" className="lg:pr-40 px-4 lg:px-0" />
          </div>
          <div className="sec2Anim2">
            <div className="">

            <h1 className="text-[#1f243c]  text-xl lg:text-3xl font-bold lg:leading-loose pt-5 pb-2 lg:py-0  ">
              What we do
            </h1>
            <h1 className="lg:w  text-[#777777] text-sm lg:text-base leading-5 lg:leading-loose pb-6 lg:pb-10 ">
              We are providing simplified experience while you purchase the{" "}
              <br className="hidden lg:block" />
              insurance policy with us online. Our team of insurance experts{" "}
              <br className="hidden lg:block" />
              will support in any phase of the insurance lifecycle, be it while{" "}
              <br className="hidden lg:block" />
              purchasing policy, claims or renewals, you can count on us!!
            </h1>
            </div>

            <div className="grid  lg:grid-cols-2 gap-4 lg:gap-7">
              {whatWeOffer.map((item) => (
                <div className="flex space-x-3">
                  <img src={Vector1} alt="Vector1" />
                  <h1 className="text-[#545454] font-semibold text-base w-max">
                    {item}
                  </h1>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Section -3 Testimonial */}
        <section className="py-8   ">
          <Testimonial />
        </section>

        {/* section -4 Articles */}
        <section className=" py-10 lg:py-20  flex justify-center">
          <Article />
        </section>

        {/* Section - 5 Partners */}
        <section className=" px-6  lg:px-32  m-auto">
          <Partners />
        </section>

        {/* Section -6 Faqs */}
        <section className="lg:py-16 lg:px-40">
          <Faq />
        </section>
        {/* Global  Footer */}
        <Footer />
      </main>
    </>
  );
};

export default HomeLanding;
