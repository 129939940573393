import React,{useState,useEffect} from 'react'

import axios from "axios";


import NavHeader from '../NavHeader'
import Footer from '../Footer'
import CardSkelton from '../../UI/CardSkelton'
import PolicyPlanCard from '../../UI/PolicyPlanCard'

import LandingBackground from "../../assets/Svg/LandingPageBck.svg";
import VehicleDetails from '../PrimaryDetails'
import { useSelector } from 'react-redux'
import { ageCalculator } from '../../utils/dateUtils'
import TermPolicyPlanCard from '../../UI/TermPolicyPlanCard'
import TermAddonLayout from '../layout/TermAddonLayout'


const TermQuotation = () => {


  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;



  const termStep1= localStorage.getItem('tm_step_1')
  const termStep2= localStorage.getItem('tm_step_2')
  const termStep3= localStorage.getItem('tm_step_3')

    const [step1_Values, setStep1_Values] = useState([])
    const [step2_Values, setStep2_Values] = useState([])
    const [step3_Values, setStep3_Values] = useState([])



  const [jwtToken, setJwtToken] = useState(localStorage.getItem("jwt_token"));





 // to set the token value to the state 
  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem("jwt_token"));
    };

    window.addEventListener("storage", onStorage);

    return () => {
      window.removeEventListener("storage", onStorage);
    };
  }, []);


    useEffect(()=>{
      if(termStep1){
        const step1 = JSON.parse(termStep1)
        setStep1_Values(step1)
        }
        if(termStep2){
          const step2 = JSON.parse(termStep2)
          setStep2_Values(step2)
          }
          if(termStep3){
            const step3 = JSON.parse(termStep3)
              setStep3_Values(step3)
              }
    },[termStep1, termStep2, termStep3])

    
    useEffect(()=>{
      axios
      .post(
        `${API_ENDPOINT}/term/quote`,
    {
      "gender": step1_Values.gender, //dyn
      "dateOfBirth": "2000-01-01", //dyn
      "smokeOrChewTobacco": 0,  //dyn if yes 1 no 0
      // "pincode": "123456",
      // "occupation": "Software Engineer",
      // "educationalQualification": "Bachelor's Degree",
      // "annualIncome": 50000,
      "sumAssuredAmount": null, 
      "firstName": "John",  //dyn
      "lastName":"doe", //dyn
      "mobile": "1234567890",
      "email": "john.doe@example.com",
      "sumAssured": 10000000,
      "coverUptoAge": 35,
      "payForYears": 12,
      "paymentFrequency": "Yearly",
      "payoutMode": "Lump-Sum"
      // "incomeTerm": 10
    },

        {
          headers: {
            authorization: jwtToken,
            "Content-Type": "application/json",
            accept: "application/json",
          }
        }
      )
      .then((res) => {  
        console.log('term insurance value',res)
      })

      .catch((error) => {
      
        console.log(error)
       
        
      });
    },[API_ENDPOINT, jwtToken])


   const preDetails = useSelector((state)=>state.termInsurace)
   console.log('pre detail',preDetails)

  return (
    <>
    <NavHeader    />
    
    <main
      className={`lg:w-full px-5 lg:px- 32 pt- bg-no-repeat bg-cover lg:overflow-x-hidden ${false?'hidden lg:block  ':''} `}
      style={{ backgroundImage: `url(${LandingBackground})` }}   
    >
      {/* Vehicle Details */}
      <div className="lg:flex gap-4">
      <div className="">

      <div className="pt-5   ">
        <VehicleDetails
          details={[step3_Values.name?.split(' ')[0],ageCalculator(step1_Values.dob),step1_Values.gender,step2_Values.annualIncome?.split(' ')[0]+step2_Values.annualIncome?.split(' ')[1]]}
          title='Primary'          
          editURL="/termInsurance/term-profile/prestep-1"
        />
      
      </div>
       
      {/* Policy Filter */}
       
      </div>

      <div className=" ">
        {/* <h2 className="text-sm lg:text-lg font-semibold">
          {policyPartnerData.length} Plans
        </h2> */}
        {/* <div className="flex text-xs  space-x-1">
          <h2 className="text-[#808080] ">found for Order Id : </h2>
          <p className="text-hero font-semibold"> MGPN3UFXF28</p>
        </div> */}
      </div>
      <hr className="text-[#CECECE] my3" />



    {/* ----------------- tile for Initial Quote Request ------------ */}
  <div className="md:w-[51%] md:m-auto lg:m-0 mt-4">
 

      {/* <div className={`  ${loader==true||false?'hidd en':''} ${initialLoad?'':'hidd en'} ${loader1?'hid den':''}    pt-5 pb-5`}> */}
      {/* <PolicyPlanCard
          detailsPop={[isDetails, setIsDetails]}
          objPolicy={policyPartnerData}
          redirectUrl='/kyc'
          dataLoading={ [loader1, setLoader1]}
          quoteData={[apiData, setApiData]}
          quoteDetailData={[quoteDetailsData, QuoteDetailsData]}
        /> */}
        <TermPolicyPlanCard
        detailsPop={[]}
        objPolicy={''}
        redirectUrl='/kyc'
        dataLoading={ []}
        quoteData={[]}
        quoteDetailData={[]}
        
        
        />
      {/* </div> */}
      <div className={`pt-1   space-y-5`}>
      <div className={`${true?'':'hidden'}`}>

    
      {/* <CardSkelton /> */}
      </div>
    
    </div>
   

      

     
      </div>

<div className="w-80">
     <TermAddonLayout/>
     </div> 

      </div>

      {/* ----------------------- End of tile here -------------- */}
      
    </main>
    {/* Global Footer */}
<div className={`${false?'hidden lg:block':''} lg:mt-40`}>

    <Footer />
</div>
  </>
  )
}

export default TermQuotation