import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { FaAngleDown } from "react-icons/fa";

const PrimaryDetails = ({ details, editURL,title='Vehicle' }) => {
  const navigate = useNavigate();
  const [modelValue, setModelValue] = useState("");
const [typeOfAction, setTypeOfAction] = useState()
  const [carInfo, setCarInfo] = useState({});
  // const details = [modelValue, carInfo.manufactureYear, carInfo.fuelType];
  const actionType = sessionStorage.getItem('action_type')
  useEffect(() => {
    const carDetails = localStorage.getItem("car_Info");
    const model = localStorage.getItem("car_Info_make_model");

    setCarInfo(JSON.parse(carDetails));
    setModelValue(model);
    setTypeOfAction(actionType)
  }, [actionType]);

  const handleClick = () => {
    navigate(editURL);
  };
  return (
    <div className={`bg-white relative shadow-[8px_8px_36px_rgba(0,167,142,0.18)] border-l-4 border-l-hero rounded-lg px-3   xl:w-[333px]   lg:px-5 py-4`}>
      <h1 className="text-[#777777] text-sm font-semibold pb-0.5 hidden lg:block">
        {title} Details
      </h1>
      <hr className="text-hero w-7 py-1 hidden lg:block" />
      <div className="flex justify-end items-center space-x-2 cursor-pointer">
        <h1
          className="flex justify-end text-sm px-5 py-2 lg:py-1 rounded-xl bg-hero text-white  items-center"
          onClick={handleClick}
        >
          Edit
        </h1>
      </div>
      <div className="lg:w-full flex    items-center  gap-x-2 lg:gap-0  lg:space-x-3">
        {details.map((item, key, row) => (
          <>
            {key < 1 && (
              <div className="lg:hidden absolute top-2 w-40  ">
                <h2 className="font-bold text-sm">{item}</h2>
              </div>
            )}
            <h1
              className={` ${
                key > 0 ? "" : "hidden lg:block"
              } text-[10px] lg:text-sm capitalize`}
              key={key}
            >
              {item}
            </h1>
            <div
              className={`${key + 1 === row.length ? "hidden" : ""} ${
                key > 0 ? "" : "hidden lg:block"
              }  w-[4px] h-[4px] bg-[#F66A6A] rounded-full`}
            />
          </>
        ))}
      </div>
    </div>
  );
};

export default PrimaryDetails;
