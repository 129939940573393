// api/quoteAPI.js
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { quoteActionsReliance } from "../../../../store/slices/quote-slice-reliance";
import { quoteActionsReliance } from "../../../store/slices/quote-slice-reliance";

import { carProposalAction } from "../../../store/slices/car-proposal";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

let isValid = false;
export const RelianceProposal = ( ) => {
    const metroCities = ["Kolkata", "Chennai", "Delhi", "Mumbai"];

  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    prevPolicyNo:"",
    existingTPExpDate:'',
    financiercity:'',
    chasisNo:'',
    engineNo:'',
    loan:'',
    financierAddress:''
 
 
  });
  const [proceed, setProceed] = useState(false);
  const [carRegisterNoError, setCarRegisterNoError] = useState(false);
  const [engineNoError, setEngineNoError] = useState(false);
  const [chasisNoError, setChasisNoError] = useState(false);
  const [carRegisterDateError, setCarRegisterDateError] = useState(false);
  const [prevPolicyInsurerError, setPrevPolicyInsurerError] = useState(false);
  const [prevTpInsurerError, setPrevTpInsurerError] = useState(false)
  const [prevPolicyNoError, setPrevPolicyNoError] = useState(false);
  const [tpPolicyNumberError, setTpPolicyNumberError] = useState(false);
  const [exisTPExpDateError, setExisTPExpDateError] = useState(false);
  const [carLoanError, setCarLoanError] = useState(false);
  const [load, setLoad] = useState(false);

  const [financierNameError, setFinancierNameError] = useState(false);
  const [financedValue, setFinancedValue] = useState(false)
  const [financierCityError, setFinancierCityError] = useState(false);
  const [financierAddress, setFinancierAddress] = useState(false);
  const [vehicleOwnershipError, setvehicleOwnershipError] = useState(false);
  const [jwtToken, setJwtToken] = useState(localStorage.getItem("jwt_token"));
  const [pincodeDetails, setPincodeDetails] = useState({});
  const [step1Values, setStep1Values] = useState({});
  const [carRegistrationNumber, setCarRegistrationNumber] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [rtoRegionCode, setRtoRegionCode] = useState("");
  const [manufactureYear, setManufactureYear] = useState();
  const [normalCarRegNo, setNormalCarRegNo] = useState();
  const [initialPolicyType, setInitialPolicyType] = useState({});
  const [productCodeValue, setProductCodeValue] = useState(false);
  const [step2Values, setStep2Values] = useState({});
  const [step3Values, setStep3Values] = useState({});
  const [step4Values, setStep4Values] = useState({});
  const [registeredModelValues, setRegisteredModelValues] = useState(null);
  const [modelRegionId, setModelRegionId] = useState({});
  const [popUpsFilterData, setPopUpsFilterData] = useState({});
  const [prevClaimValue, setPrevClaimValue] = useState();
  const [idvValue, setIdvValue] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();
  const [isCarRegistered, setIsCarRegistered] = useState();
  const [tileType, setTileType] = useState();
  const [popData, setPopData] = useState();
  const [selectedNCB, setSelectedNCB] = useState(null);

  const [ratesValues, setRatesValues] = useState();
const [rsaKycApiData, setRsaKycApiData] = useState()
const [ownership, setOwnership] = useState()
const [reasonCpaVal, setReasonCpaVal] = useState([])
const [tppdChange, setTppdChange] = useState()
const [prevAddons, setPrevAddons] = useState()
const [carPucError, setCarPucError] = useState(false)
  const [finalAddons, setFinalAddons] = useState({});
  const [tpNewDate, setTpNewDate] = useState();
  const [finalAddonsInput, setFinalAddonsInput] = useState({});
  const [inputDiscount, setInputDiscount] = useState();
  const [manufactureMonth, setManufactureMonth] = useState();
  const [masterID, setMasterID] = useState({});
  const [inputIdvValue, setInputIdvValue] = useState()
//   const [previousInsurerId, setPreviousInsurerId] = useState({});
  const [prevTpInsurerId, setPrevTpInsurerId] = useState({})
  const [previousInsurerId2, setPreviousInsurerId2] = useState({});
  const [prevInsurerData, setPrevInsurerData] = useState([]);
  const [prevInsurerData2, setPrevInsurerData2] = useState([]);
  const [loadAgain, setLoadAgain] = useState(false);
  const [isPopWindowOpen, setIsPopWindowOpen] = useState(false);
  const [closeNav, setCloseNav] = useState(false);
  const [tpExpireDate, setTpExpireDate] = useState('');
  const [tpStartDate, setTpStartDate] = useState('')
  const [makeErrorTrue, setMakeErrorTrue] = useState(false);
const [modelName, setModelName] = useState('')
const [policyTerm, setPolicyTerm] = useState(1)
  const [godigitKycDetails, setGodigitKycDetails] = useState([]);
  const carRegistered = localStorage.getItem("isCarRegistered");
  const filterData = sessionStorage.getItem("inputs_policyType");
  const godigitKycData = localStorage.getItem("godigitKycInput");
  let registeredModelValue = localStorage.getItem("registered_model");
const rsaKycApi = localStorage.getItem('rsaKyc_apidata')
  const carOwnerDetails = localStorage.getItem("step-1_Values");
  const personalDetails = localStorage.getItem("step-2_Values");
  const addressDetails = localStorage.getItem("step-3_Values");
  const vechileDetails = localStorage.getItem("step-4_Values");
  const pincodeData = localStorage.getItem("pincode_Details");
  const insurerId = localStorage.getItem("insurance_Id");
  const insurerTpId = localStorage.getItem("insurance-Tp_Id");
  let registeredModelOwnershipValue= localStorage.getItem('registered_model_ownership')
const inputIdvVal = sessionStorage.getItem('inputs_IDV')
  const radioValue = localStorage.getItem("car_Info");
  let registeredModelClaimValue = localStorage.getItem(
    "registered_model_claim"
  );
  const cpaValues = sessionStorage.getItem('cpaValues');
  const tppdVal = sessionStorage.getItem('tppdVal')

  const carRegNO = localStorage.getItem("car_Intro");
  const modelRegion = localStorage.getItem("regionCode");
  const tileValue = localStorage.getItem("tile_Type_Detail");
  const apidata = localStorage.getItem("apiData");
  const finaladdon = sessionStorage.getItem("input_addons_check");
  const finaladdoninput = sessionStorage.getItem("check_inputs");
  const finalDiscount = sessionStorage.getItem("inputs_discount");
  const masteridvalues = localStorage.getItem("master_ID");
  const prevPolicyHolderNames = localStorage.getItem("prev_policy_names");
  const rateValue = localStorage.getItem("ratesValues");
  const prevOptedAddon = sessionStorage.getItem('prevAddons')
  const model = localStorage.getItem("car_Info_make_model");
  const currentNcbVal = sessionStorage.getItem('currentNcb')

  const [proposalApiData, setProposalApiData] = useState();
  const [isDataLoaded, seTisDataLoaded] = useState();
  /* -------------------  API ENDPOINT -------------- */
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  /* ---------------------------------- -------------- */
  const dispatch = useDispatch();



  /* --------------- Redux Values ------------------------ */
  let rsaApiData = useSelector(state=>state.quoteRsa.apiData)

  let godigitApiData = useSelector(state=>state.quoteGodigit.apiData)

  let relianceApiData = useSelector(state=>state.quoteReliance.apiData)

  /* --------------------------------------------------------- */

  // useEffect(()=>{
  //   if(Object.values(masterID).length>0){
  //     setLoad(true)
  //   }
  // },[masterID])

  useEffect(() => {
    setTileType(tileValue);
    setPopData(JSON.parse(apidata));
  }, [tileValue, apidata]);
  useEffect(() => {
    const vehicleNumberHandle = () => {
      let dob = [""];
      let spliting = step2Values.dateOfBirth.split("-");
      dob.unshift(spliting);
      setDateOfBirth(dob[0][2] + "/" + dob[0][1] + "/" + dob[0][0]);
    };
    step2Values.dateOfBirth && vehicleNumberHandle();
  }, [step2Values.dateOfBirth]);

  // useEffect(() => {
  //   setIdvValue(
  //     (tileType == "Reliance" &&
  //     Object.keys(relianceApiData).length>0  &&relianceApiData.MotorPolicy.IDV) ||
  //       (tileType == "GoDigit" &&
  //       Object.keys(godigitApiData).length>0 && godigitApiData.vehicle.vehicleIDV.idv) ||
  //       (tileType == "RSA" &&
  //       Object.keys(rsaApiData).length>0&&rsaApiData.PREMIUMDETAILS.DATA.MODEL_IDV)
  //   );
  // }, [godigitApiData, relianceApiData, rsaApiData, tileType]);

  useEffect(() => {
    setPopUpsFilterData(filterData);
    setRegisteredModelValues(JSON.parse(registeredModelValue));
    setPrevClaimValue(registeredModelClaimValue);
    carRegNO !== null
      ? setCarRegistrationNumber(JSON.parse(carRegNO).registrationNumber)
      : setCarRegistrationNumber(inputs.carRegisterNo);
    setRsaKycApiData(JSON.parse(rsaKycApi))
    setManufactureYear(JSON.parse(radioValue).manufactureYear);
setModelName(model)

    carRegNO && setNormalCarRegNo(JSON.parse(carRegNO).registrationNumber);
    setInitialPolicyType(JSON.parse(radioValue));
    setPincodeDetails(JSON.parse(pincodeData));
    setModelRegionId(JSON.parse(modelRegion));
    setStep1Values(JSON.parse(carOwnerDetails));
    setStep2Values(JSON.parse(personalDetails));
    setStep3Values(JSON.parse(addressDetails));
    setStep4Values(JSON.parse(vechileDetails));
    setFinalAddonsInput(JSON.parse(finaladdoninput));
    setPolicyTerm(finaladdoninput? JSON.parse(finaladdoninput).checkInput4:1);
    setSelectedNCB(currentNcbVal)

    setInputDiscount(finalDiscount);
    setOwnership(registeredModelOwnershipValue)
    setInputIdvValue(inputIdvVal)
    setMasterID(JSON.parse(masteridvalues));
  setReasonCpaVal(cpaValues)
  setTppdChange(tppdVal)
  setPrevAddons(prevOptedAddon)

    setPrevInsurerData2(JSON.parse(prevPolicyHolderNames));
    setGodigitKycDetails(JSON.parse(godigitKycData));
    setFinalAddons(JSON.parse(finaladdon));
    // load&&setPreviousInsurerId2(JSON.parse(insurerId))
    setRatesValues(JSON.parse(rateValue));

    setIsCarRegistered(carRegistered);
  }, [carOwnerDetails, prevOptedAddon, load, cpaValues, registeredModelOwnershipValue, rsaKycApi, rateValue, inputs, inputIdvVal, godigitKycData, prevPolicyHolderNames, finalDiscount, insurerId, masteridvalues, finaladdoninput, carRegistered, finaladdon, modelRegion, pincodeData, radioValue, carRegNO, registeredModelClaimValue, vechileDetails, filterData, registeredModelValue, addressDetails, personalDetails, tppdVal, model, currentNcbVal]);

  /* ----------------- Date Logics --------------------*/

  const userPrevPolicyExp =
    registeredModelValues && registeredModelValues.policyExp;
  const startDate = new Date(isCarRegistered =='true'? userPrevPolicyExp : Date.now());

 
  const policyStartDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000); // Add one day in milliseconds
  const policyEndDate = new Date(
    startDate.getTime() + 365 * 24 * 60 * 60 * 1000
  ); // Add one year in milliseconds
  const tppolicyEndDate = new Date(
    startDate.getTime() + 3 * 365 * 24 * 60 * 60 * 1000
  );

  const formatDate = date =>
  `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;

// Calculate and format prevPolicyStartDate as one year earlier and one day more
const prevPolicyStartDate = formatDate(new Date(startDate.getFullYear() - 1, startDate.getMonth(), startDate.getDate() + 1));

// prevPolicyEndDate remains the same as startDate
const prevPolicyEndDate = formatDate(startDate);

  /* ---------------- Date Logics End Here ---------------------  */

  /* ---------------------- To Update the Policy type value  -------------------------*/
//   const updateIdvTypeValueReliance = (value) => {
//     setIdVValue(value);
//   };

//   /* -------------------------------------------------------------- */
//   useEffect(()=>{
//     if(finalpolicytype){
//       setFinalPolicyType(finalpolicytype)
//     }else {
//       setFinalPolicyType(defaultRadioValue&&defaultRadioValue.policy)
//     }
//   },[defaultRadioValue, finalpolicytype])

  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem("jwt_token"));
    };

    window.addEventListener("storage", onStorage);

    return () => {
      window.removeEventListener("storage", onStorage);
    };
  }, []);

  // console.log('isVlue',isValuesRead,carInfo,modelRegionId,masterID,inputStatus)
  useEffect(() => {
    dispatch(quoteActionsReliance.setInputsRead());

    //  callbackfun()
  }, []);

  const updateInputValues = (valueSet) =>{
    setInputs({
        prevPolicyNo:valueSet.prevPolicyNo,
        existingTPExpDate:valueSet.existingTPExpDate,
        financiercity:valueSet.financiercity,
        chasisNo:valueSet.chasisNo,
        engineNo:valueSet.engineNo,
        loan:valueSet.loan,
        financierAddress:valueSet.financierAddress,
        previousInsurerId:valueSet.previousInsurerId
    })
  }

  const callbackfun =  ()=>{
 

    // if (
    //   Object.keys(carInfo).length > 0 &&
    //   Object.keys(modelRegionId).length > 0 &&
    //   Object.keys(masterID).length > 0&&
    //   Object.keys(finalAddons).length > 0&&
    //   selectedNCB!==null&&
    //   (finalPolicyType!==null||finalPolicyType!==undefined)
    // ) {
    //   setMakeRequestSuc(true)
    //   sessionStorage.setItem("inputSet", true);
    //   dispatch(quoteActionsReliance.setInputsRead());
    // } else {
    //   sessionStorage.setItem("inputSet", false);
    //   dispatch(quoteActionsReliance.setInputsRead());
    // }
 
    }

      /* ------------------ Manufacture Month ----------------------- */
  const manufacturemonth = new Date(initialPolicyType.registrationDate);
  let carMonth = manufacturemonth.getMonth() + 1;
  if (carMonth < 10) {
    carMonth = "0" + carMonth;
  }
  useEffect(() => {
    setVehicleNumber(
        carRegistrationNumber&&  carRegistrationNumber.replace(
        /^([a-zA-Z]{2})(\d{2})([a-zA-Z]{2})(\d+)$/,
        "$1-$2-$3-$4"
      )
    );
  }, [carRegistrationNumber]);
 
 
  const fetchProposalReliance = () => {
    dispatch(quoteActionsReliance.setQuoteLoading(true));

    axios
    .post(
      `${API_ENDPOINT}/proposal/reliance`,
      //
      {
        productCode:
          isCarRegistered == "true" || isCarRegistered == true
            ? (productCodeValue
                ? popUpsFilterData == "comprehensive" && 2311
                : initialPolicyType.policy == "comprehensive" && 2311) ||
              ((productCodeValue
                ? popUpsFilterData == "thirdParty" && 2347
                : initialPolicyType.policy == "thirdParty") &&
                2347) ||
              (productCodeValue
                ? popUpsFilterData == "ownDamage" && 2309
                : initialPolicyType.policy == "ownDamage" && 2309)
            : (productCodeValue
                ? popUpsFilterData == "bundled" && 2374
                : initialPolicyType.policy == "bundled" && 2374) ||
              (productCodeValue
                ? popUpsFilterData == "thirdParty" && 2371
                : initialPolicyType.policy == "thirdParty" && 2371), //based on vehicle type

        clientDetails: {
          // "clientType": 0,
          clientType: step1Values.registered == "yes" ? 0 : 0, // registered in company : yes ?1:0

          // "lastName": "deshmukh",
          lastName: step1Values.lastName,
          // "foreName": "priyanka",
          foreName: step1Values.firstName,
          dob: dateOfBirth,
          // "dob": "01/01/1991",
          // "gender": 2,
          gender: step2Values.gender == "male" ? 1 : 2,
          mobileNo: step1Values.mobile,
          // "mobileNo": step1Values.mobile,
          // "clientAddress":
          // {
          //   "communicationAddress":
          //    {
          //     "addressType": 0,
          //     "address1": 'fsadfdsf',
          //   "address2": "agggaaa",
          //   "address3": "strifffffng",
          //   "cityId":pincodeDetails.cityOrVillageId, // cityid

          //   "districtId":pincodeDetails.districtId,
          //   "stateId": pincodeDetails.stateId, // state id

          //   // "pinCode": '400006',
          //   "pinCode":  step3Values.pincode,

          //   "country": "India"
          // },
          // "permanentAddress": {
          //   "addressType": 0,
          //   "address1": 'step3Values.address',
          //   "address2": "aaaffa",
          //   "address3": "strifffffng",
          //   "cityId":pincodeDetails.cityOrVillageId, // cityid

          //   "districtId":pincodeDetails.districtId,
          //   "stateId": pincodeDetails.stateId, // state id

          //   // "pinCode": '400006',
          //   "pinCode":  step3Values.pincode,

          //   "country": "India"
          // },
          // "registrationAddress": {
          //   "addressType": 0,
          //   // "address1": "Bungalow No. 101",
          //   "address1":'chinthaguda',
          //   "address2": "aaddaa",
          //   "address3": "strifffffng",
          //   "cityId":pincodeDetails.cityOrVillageId, // cityid

          //   "districtId":pincodeDetails.districtId,
          //   "stateId": pincodeDetails.stateId, // state id

          //   // "pinCode": '400006',
          //   "pinCode":  step3Values.pincode,

          //   "country": "India"
          // }
          // },
          clientAddress: {
            communicationAddress: {
              addressType: 0,
              address1: step3Values.address,
              address2: step3Values.address2,
              address3: step3Values.address2,
              cityId: pincodeDetails.cityOrVillageId,
              districtId: pincodeDetails.districtId,
              stateId: pincodeDetails.stateId,
              pincode: step3Values.pincode,
              country: "India"
            },
            permanentAddress: {
              addressType: 0,
              address1: step3Values.address,
              address2: step3Values.address2,
              address3: step3Values.address2,
              cityId: pincodeDetails.cityOrVillageId,
              districtId: pincodeDetails.districtId,
              stateId: pincodeDetails.stateId,
              pincode: step3Values.pincode,
              country: "India"
            },
            registrationAddress: {
              addressType: 0,
              address1: step3Values.address,
              address2: step3Values.address2,
              address3: step3Values.address2,
              cityId: pincodeDetails.cityOrVillageId,
              districtId: pincodeDetails.districtId,
              stateId: pincodeDetails.stateId,
              pinCode: step3Values.pincode,
              country: "India"
            }
          },
          // "emailId": "priyanka.deshmukh@relianceada.com",
          emailId: step1Values.email,
          // "salutation": "Mrs.",
          salutation: `${step1Values.salutation}.`,
          // "maritalStatus": "1951",
          maritalStatus: step2Values.married == "yes" ? "1951" : "1952",
          // registeredUnderGst: step1Values.registered == "yes" ? 1 : 0,
          // "gstin": "27AASCS2460H1Z0"
          gstin: null
        },
        policy: {
          // "tpPolicyNumber": "PB9867786234554321",
          tpPolicyNumber: (
            popUpsFilterData == (undefined || null)
              ? initialPolicyType.policy == "ownDamage"
              : popUpsFilterData == "ownDamage"
          )
            ? step4Values.tpPolicyNumber
            : inputs.prevPolicyNo,

          tpPolicyStartDate: initialPolicyType.registrationDate,
          // "tpPolicyEndDate": "2024-08-21",
          tpPolicyEndDate:
          inputs.existingTPExpDate &&
            inputs.existingTPExpDate.split().reverse().join(),
          tpPolicyInsurer: inputs.previousInsurerId,
          // "businessType": "5",
          businessType:
            isCarRegistered == "true" || isCarRegistered == true
              ? "5"
              : "1",
          // "coverFrom": "24/12/2022",
          "coverFrom":   policyStartDate
          .toLocaleDateString("en-GB")
          .replace(/\//g, "-")
          .split("-")
          .reverse()
          .join("-"), // user inpuut 
 
          "coverTo":  (!isCarRegistered&&(popUpsFilterData==(null||undefined)?initialPolicyType.policy == "thirdParty":popUpsFilterData=='thirdParty' ))?tppolicyEndDate.toLocaleDateString("en-GB")
          .replace(/\//g, "-")
          .split("-")
          .reverse()
          .join("-"): policyEndDate
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-")
            .split("-")
            .reverse()
            .join("-") , // -1 day from input
          // "coverTo": '2024-02-29' , // -1 day from input
         

          branchCode: "9202",
          agentName: "Direct",
          // "productCode": "2311",
          productCode:
            isCarRegistered == "true" || isCarRegistered == true
              ? (productCodeValue
                  ? popUpsFilterData == "comprehensive" && 2311
                  : initialPolicyType.policy == "comprehensive" && 2311) ||
                ((productCodeValue
                  ? popUpsFilterData == "thirdParty" && 2347
                  : initialPolicyType.policy == "thirdParty") &&
                  2347) ||
                (productCodeValue
                  ? popUpsFilterData == "ownDamage" && 2309
                  : initialPolicyType.policy == "ownDamage" && 2309)
              : (productCodeValue
                  ? popUpsFilterData == "bundled" && 2374
                  : initialPolicyType.policy == "bundled" && 2374) ||
                (productCodeValue
                  ? popUpsFilterData == "thirdParty" && 2371
                  : initialPolicyType.policy == "thirdParty" && 2371), //based on vehicle type

          otherSystemName: "1",
          isMotorQuote: "false"
        },
        risk: {
          financierCity: inputs.financiercity,
          exShowroomPrice: "",
          chassis: inputs.chasisNo,
          financeType: inputs.loan == "yes" ? 3 : 2,
          // stateOfRegistrationID: 21, // pincode api
          stateOfRegistrationID: modelRegionId.reliance.stateId, // pincode api
          isHavingValidDrivingLicense: true,
          isOptedStandaloneCPAPolicy:
            finalAddons && finalAddons.ownerDriverPAcover ? false : true,
          // "vehicleVariant": "LXI CNG",
          // "zone":'A',
          zone: modelRegionId.reliance.modelZoneName, // use region rto zone
          financierAddress: inputs.financierAddress,
          rto_RegionCode: modelRegionId&&modelRegionId.reliance.regionCode.replace("-", ""),

          // "cubicCapacity": 998, //not required
          manufactureYear: manufactureYear,
          vehicleMakeID: masterID.reliance[0].makeId, // master id make id
          financierName: inputs.financierName,
          isPermanentAddressSameasCommAddress: true,
          isVehicleHypothicated: inputs.loan == "yes" ? true : false,
          isRegAddressSameasPermanentAddress: true,
          vehicleModelID: masterID.reliance[0].modelId, // master is model id
          isRegAddressSameasCommAddress: true,
          manufactureMonth: carMonth, // car registration date do
          // "dateOfPurchase": "2019-07-01",
          dateOfPurchase: initialPolicyType.registrationDate,
          engineNo: inputs.engineNo,
          // "rtolocationID": 571,
          // rtolocationID: 571, // api regioncode modelRegionId
          rtolocationID: modelRegionId.reliance.modelRegionId, // api regioncode modelRegionId
          idv: +idvValue // idv quote
          // idv: 643410 // idv quote
          // idv: 873348.0
        },

        vehicle: {
          seatingCapacity: 5,
          // registrationNumber: 'NEW',
          registrationNumber: isCarRegistered=='true'? carRegistrationNumber.replace(/(\w{2})(\d{2})(\w{2})(\d+)/, '$1-$2-$3-$4')
          : `NEW`,
          registrationDate: initialPolicyType.registrationDate,
          typeOfFuel: (!["" || null || undefined].includes(finalAddons)&& finalAddons.externalBiFuelkit)?5:
            (initialPolicyType.fuelType == "petrol" && 1) ||
            (initialPolicyType.fuelType == "diesel" && 2) ||
            (['petrol+cng','cng','lpg','petrol+lpg','inbuilt'].includes(initialPolicyType.fuelType) &&5)||
            (initialPolicyType.fuelType == "electric" && 6)
          // typeOfFuel: "1"
        },
        cover: {
          isBasicODCoverage:
            (popUpsFilterData && popUpsFilterData == "thirdParty") ||
            (initialPolicyType && initialPolicyType.policy == "thirdParty")
              ? false
              : true,
          lstTaxComponentDetails: "",

          basicLiability:
          (( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="ownDamage":popUpsFilterData=="ownDamage")
              ? false
              : true,

          isVoluntaryDeductableOpted:
            inputDiscount == ("" || null || undefined)
              ? false
              :(( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="thirdParty":popUpsFilterData=="thirdParty")
              ? false
              : inputDiscount == "None"
              ? false
              : inputDiscount == "None"
              ? false
              : true, // dicut addons
          voluntaryDeductible: {
            voluntaryDeductible: {
              sumInsured:
                inputDiscount == ("" || null || undefined)
                  ? "0"
                  :(( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="thirdParty":popUpsFilterData=="thirdParty")
                  ? "0"
                  : inputDiscount == "None"
                  ? "0"
                  : inputDiscount /// discount value
            }
          },

          // "sumInsured": "7500",
          isAntiTheftDeviceFitted: false,
          antiTheftDeviceDiscount: {
            antiTheftDeviceDiscount: {
              packageName: "",
              isMandatory: true,
              noOfItems: "1",
              isChecked: true
            }
          },
          isPAToOwnerDriverCoverd:
            finalAddons == ("" || null || undefined)
              ? false
              : finalAddons.ownerDriverPAcover
              ? true
              : false,
          paCoverToOwner: {
            // owner pa driver from addons
            paCoverToOwner: {
              nomineeName: step2Values.nomineeName, // nominee name from 2nd step
              nomineeDob: step2Values.nomineedateOfBirth, // nominee dob from 2nd form
              nomineeRelationship: step2Values.relation, // from 2nd form
              appointeeName: step2Values.appointeeName,
              cpaCovertenure: "1",
              nomineeAddress: ""
            }
          },
          isPAToDriverCovered:
            finalAddons == ("" || null || undefined)
              ? false
              : finalAddons.paidDrivercover
              ? true
              : false,

          paToPaidDriver: {
            paToPaidDriver: {
              noOfItems: 1,
              sumInsured: 200000
            }
          },
          isPAToNamedPassenger: false,
          paToNamedPassenger: {
            paToNamedPassenger: {
              noOfItems: "3",
              sumInsured: "100000",
              isMandatory: false,
              isChecked: false,
              passengerName: "1",
              nomineeName: "2",
              nomineeDob: "3",
              nomineeRelationship: "4",
              nomineeAddress: "5",
              otherRelation: "6",
              appointeeName: "7",
              packageName: "8"
            }
          }, // false
          isPAToUnnamedPassengerCovered:
            finalAddons == ("" || null || undefined)
              ? false
              : finalAddons.unnamedpassengerPAcover
              ? true
              : false, // addons unnamed
          paToUnNamedPassenger: {
            paToUnNamedPassenger: {
              noOfItems: "5",
              sumInsured: "200000"
            }
          },

          isLiabilityToPaidDriverCovered: false,
          liabilityToPaidDriver: {
            liabilityToPaidDriver: {
              noOfItems: "5"
            } // false
          },
          isTppdCover:
             (( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="ownDamage":popUpsFilterData=="ownDamage")
              ? false
              : true,
          tppdCover: {
            tppdCover: {
              sumInsured: "750000",
              isChecked:
              (( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="ownDamage":popUpsFilterData=="ownDamage")
                  ? false
                  : true
            }
          },

          isNilDepreciation:
            finalAddons == ("" || null || undefined)
              ? false
              :(( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="thirdParty":popUpsFilterData=="thirdParty")
              ? false
              : finalAddons.zeroDepreciation &&
                (finalAddons.consumables ||
                  finalAddons.engineProtect ||
                  finalAddons.keyLoss)
              ? false
              : finalAddons.zeroDepreciation
              ? true
              : false, // from addon
          nilDepreciationCoverage: {
            nilDepreciationCoverage: {
              applicableRate: ratesValues && ratesValues[0]
            }
          },
          isSecurePlus:
            finalAddons == ("" || null || undefined)
              ? false
              :(( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="thirdParty":popUpsFilterData=="thirdParty")
              ? false
              : ((finalAddons.zeroDepreciation &&
                  finalAddons.consumables &&
                  finalAddons.engineProtect&&finalAddons.lossOfPersonalBelongings) ||
                  (finalAddons.consumables && finalAddons.engineProtect&&finalAddons.lossOfPersonalBelongings) ||
                  finalAddons.consumables ||
                  finalAddons.engineProtect||finalAddons.lossOfPersonalBelongings) &&
                finalAddons.tyreProtection !== true
              ? true
              : false, // true or false from addons
          securePlus: {
            securePlus: {
              applicableRate: ratesValues && ratesValues[1]
            }
          },
          isSecurePremium:
            finalAddons == ("" || null || undefined)
              ? false
              :(( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="thirdParty":popUpsFilterData=="thirdParty")
              ? false
              : finalAddons.tyreProtection
              ? true
              : false, // true or false
          securePremium: {
            securePremium: {
              applicableRate: ratesValues && ratesValues[2]
            }
          },
          // "isBiFuelKit":inputs.externalKit=='externalKityes'? true:false,

          // isBiFuelKit: true,
          isBiFuelKit: ['petrol+cng','petrol+lpg','cng','inbuilt'].includes(initialPolicyType.fuelType) ?  (!["" || null || undefined].includes(finalAddons)&& finalAddons.externalBiFuelkit)
          ? false
          :true  : (!["" || null || undefined].includes(finalAddons)&& finalAddons.externalBiFuelkit)
          ? true
          :false,
          // isBiFuelKit:
          //   finalAddons == null || undefined
          //     ? false
          //     :   finalAddons.externalBiFuelkit
          //     ? false
          //     : false,
          bifuelKit: {
            bifuelKit: {
              sumInsured:
                (finalAddons == null || undefined) &&
                (finalAddonsInput == null || undefined)
                  ? 0
                  : finalAddonsInput && finalAddonsInput.checkInput3, // from addons bifuel input

              // islpgCng: true,
              islpgCng:
              ['petrol+cng','petrol+lpg','inbuilt'].includes(initialPolicyType.fuelType) ? (!["" || null || undefined].includes(finalAddons)&& finalAddons.externalBiFuelkit)
              ? false
              :true :(!["" || null || undefined].includes(finalAddons)&& finalAddons.externalBiFuelkit)
              ? false
              :true,
              policyCoverDetailsID: {},
              // isChecked: true,
              isChecked:true,
              fueltype:
                initialPolicyType.fuelType == ("petrol+cng" || "cng")
                  ? "CNG"
                  : "LPG",
              isMandatory: false
            }
          },

          // "biFuelKit": initialPolicyType.fuelType == ('petrol+cng'||'petrol+lpg')?true:false,//
          // "newVehicle":  false, // this need to be cleared
          // "basicODCoverage":oldPolicyType=="thirdParty"?false: true,
          // "biFuelIsChecked": initialPolicyType.fuelType == ('petrol+cng'||'petrol+lpg')?true:false,//
          // "biFuelIsMandatory": false,//
          // "biFuelIsLpgCng": initialPolicyType.fuelType == ('petrol+cng'||'petrol+lpg')?true:false, //
          // "biFuelSumInsured": 100.0,
          // "biFuelFuelType": initialPolicyType.fuelType == 'petrol+cng'?"CNG":"LPG",//

          //   "electricItemFitted":false, //
          //   "isPAToOwnerDriverCoverd":false,
          //    "nonElectricalItemFitted":false,
          //  "basicLiability":false

          electricItems: "",
          nonElectricItems: "",

          electricalItemFitted:
            finalAddons == null || undefined
              ? false
              : finalAddons.electricalaccessories
              ? true
              : false, // based on addon api ele,
          electricalItemsTotalSI:
            finalAddons == null || undefined
              ? 0
              : finalAddonsInput && finalAddonsInput.checkInput1, // adddon ele input value

          nonElectricalItemFitted:
            finalAddons == null || undefined
              ? false
              : finalAddons.nonElectricalaccessories
              ? true
              : false, //based on addons api nonel false ;true,
          nonElectricalItemsTotalSI:
            finalAddons == null || undefined
              ? 0
              : finalAddonsInput && finalAddonsInput.checkInput2 // addons none ele inut value
        },

        previousInsuranceDetails: {
          // "prevYearInsurer":'2',
          prevYearInsurer: inputs.previousInsurerId,
          isPreviousPolicyDetailsAvailable: "true",
          prevYearInsurerAddress: "",
          prevYearPolicyType:"1",
          prevYearPolicyNo: inputs.prevPolicyNo,
          // prevYearPolicyStartDate:'01/03/2022',

          prevYearPolicyStartDate:
          isCarRegistered ? prevPolicyStartDate.split("-").reverse().join("-"): "",
          prevYearPolicyEndDate:
          isCarRegistered ? prevPolicyEndDate.split("-").reverse().join("-")  : ""
        },

        ncbEligibility: {
          currentNcb:
          selectedNCB&&
          ((registeredModelValues &&
            registeredModelValues.prevNCB == (null || undefined)) ||
          prevClaimValue == "Yes"
            ? 0
            : selectedNCB),
          ncbeligibilityCriteria: prevClaimValue == "Yes" ? "1" : "2",
          previousNcb:
            (registeredModelValues &&
              registeredModelValues.prevNCB == (null || undefined)) ||
            prevClaimValue == "Yes"
              ? 0
              : registeredModelValues && registeredModelValues.prevNCB
        },
        //  "productcode": "2311",
        userId: "100002",
        sourceSystemId: "100002",
        authToken: "Pass@123",
        existingRGICustomer: false
      },

      {
        headers: {
          authorization: jwtToken,
          "Content-Type": "application/json",
          accept: "application/json",
          "x-rsa-type": 1
        }
      }
    )
    .then((res) => {
      // setApiData(res);
      setProposalApiData(res.data.MotorPolicy);
          seTisDataLoaded(true);
          localStorage.setItem(
            "payment_Data",
            JSON.stringify(res.data.MotorPolicy)
          );
          localStorage.setItem("proposal_loaded", true);
          dispatch(carProposalAction.setApiFailed(false));
          dispatch(carProposalAction.setProposalApiData(res.data.MotorPolicy));

    })

    .catch((error) => {
      dispatch(carProposalAction.setApiFailed(true));
      console.log(error)});
    // setIsValidReq(false)

  };

  return { fetchProposalReliance,callbackfun ,updateInputValues};
};