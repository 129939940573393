// api/quoteAPI.js
import { useState ,useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import   { quoteActions } from '../../../../store/slices/quote-slice-rsa';
import audioFile from '../../../../assets/music/song.mp3'

import axios from 'axios';


export const RsaPolicyQuote = () => {
    const [vehicleNumber, setVehicleNumber] = useState('')
    const [carRegistrationNumber, setCarRegistrationNumber] = useState('')
    const [carInfo, setCarInfo] = useState({});
  const [oldPolicyType, setOldPolicyType] = useState()
const [inputStatus, setInputStatus] = useState(false)
// const [closePopUp, setClosePopUp] = closeState;
const [isCoupenSelected, setIsCoupenSelected] = useState(false);
const [policyType, setPolicyType] = useState({});

const [inputAddon, setInputAddon] = useState({});
const [isDisActive, setIsDisActive] = useState();
const [finalAddonsInput, setFinalAddonsInput] = useState({});

// const [placeHolderValue, setPlaceHolderValue] = placeValue;
// const [jwtToken, setJwtToken] = useState('')
const [rtoRegionCode, setRtoRegionCode] = useState("");
const [defaultRadioValue, setDefaultRadioValue] = useState();
const [addonsData, setAddonsData] = useState({});
const [inputDiscount, setInputDiscount] = useState();
const [finalIDVValue, setFinalIDVValue] = useState();
const [resetButton, setResetButton] = useState(false);
const [noChange, setNoChange] = useState(false)
const [selectedNCB, setSelectedNCB] = useState(0);
const [reasonCpaVal, setReasonCpaVal] = useState()
const [tppdChange, setTppdChange] = useState(true)
const [prevAddons, setPrevAddons] = useState()
const [isJWTCall, setIsJWTCall] = useState(false);
const [quoteLoading, setquoteLoading] = useState(false);
const [apiData, setApiData] = useState({});
const [manufactureYear, setManufactureYear] = useState();
const [initialPolicyType, setInitialPolicyType] = useState({});
const [masterID, setMasterID] = useState({});
const [rsaIdvValue, setRsaIdvValue] = useState()
const [tokenLoaded, setTokenLoaded] = useState(false);
const [prevClaimValue, setPrevClaimValue] = useState();
const [registeredModelValues, setRegisteredModelValues] = useState({});
const [popUpsFilterData, setPopUpsFilterData] = useState({});
const [makeRequest, setMakeRequest] = useState(false);
const [ownership, setOwnership] = useState()
const [changeAction, setChangeAction] = useState(false)
const [CarIsRegistered, setCarIsRegistered] = useState();
const [changeButtonValue, setChangeButtonValue] = useState(true)
const [modelRegionId, setModelRegionId] = useState({});
const [ratesValues, setRatesValues] = useState()
const [radioValues, setRadioValues] = useState(true)
const [buttonValue, setButtonValue] = useState();
const [jwtToken, setJwtToken] = useState(localStorage.getItem("jwt_token"));
const [finalAddons, setFinalAddons] = useState({});
// For Counting Number of Checked values in Addons
const selectAddonOption = [""];
const trueValues = Object.values(inputAddon);
trueValues.forEach(function (x) {
  selectAddonOption[x] = (selectAddonOption[x] || 0) + 1;
});
localStorage.setItem(
  "checked_addons",
  addonsData && selectAddonOption.true == undefined
    ? "0"
    : selectAddonOption.true
);


const [isValidReq, setIsValidReq] = useState(true)
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
  const dispatch = useDispatch();

//  State from Redux 
let isValuesRead = useSelector(state=>state.quoteRsa.isInputsRead)


//Values from LocalStorage 
  // const filterData = localStorage.getItem("popupsData");
const inputActive = sessionStorage.getItem("inputSet")
let registeredModelValue = localStorage.getItem("registered_model");
let registeredModelClaimValue = localStorage.getItem(
  "registered_model_claim"
);
const cpaValues = sessionStorage.getItem('cpaValues');

const filterData = localStorage.getItem("popupsData");
const carRegistered = localStorage.getItem("isCarRegistered");
const radioValue = localStorage.getItem("car_Info");
const carRegNO = localStorage.getItem("car_Intro");
const finaladdon = sessionStorage.getItem("input_addons_check");
const finalDiscount = sessionStorage.getItem("inputs_discount");
const finalidvvalue = sessionStorage.getItem("inputs_IDV");
const finaladdoninput = sessionStorage.getItem("check_inputs");
const modelRegion = localStorage.getItem("regionCode");
const masteridvalues = localStorage.getItem("master_ID");
const rateValue = localStorage.getItem('ratesValues')
const rsaInitIdv = localStorage.getItem("rsaIdvvalue")
const requestSta = localStorage.getItem('apiLoaded')
const tppdVal = sessionStorage.getItem('tppdVal')
const prevOptedAddon = sessionStorage.getItem('prevAddons')
// const policyType = sessionStorage.getItem('inputs_policyType')

let registeredModelOwnershipValue= localStorage.getItem('registered_model_ownership')

useEffect(()=>{
setInputStatus(inputActive)

setPopUpsFilterData(JSON.parse(filterData));
setRsaIdvValue(rsaInitIdv)
setCarIsRegistered(JSON.parse(carRegistered));
setDefaultRadioValue(JSON.parse(radioValue));
setRegisteredModelValues(JSON.parse(registeredModelValue));
setPrevClaimValue(registeredModelClaimValue);
// setPolicyType(policyType)
setManufactureYear(JSON.parse(radioValue).manufactureYear);
carRegNO&& setCarRegistrationNumber(JSON.parse(carRegNO).registrationNumber);
// console.log('im calling again and again',popUpsFilterData.policyData)
setFinalAddons(JSON.parse(finaladdon));
setInputDiscount(finalDiscount);
setOwnership(registeredModelOwnershipValue)
setNoChange(requestSta)
setReasonCpaVal(cpaValues)
setTppdChange(tppdVal)
setPrevAddons(prevOptedAddon)
setFinalIDVValue(finalidvvalue);
setFinalAddonsInput(JSON.parse(finaladdoninput));
setModelRegionId(JSON.parse(modelRegion));
setInitialPolicyType(JSON.parse(radioValue));
setMasterID(JSON.parse(masteridvalues));
setRatesValues(JSON.parse(rateValue))
setOldPolicyType(JSON.parse(radioValue).policy)

},[carRegNO, carRegistered, cpaValues, filterData, finalDiscount, finaladdon, finaladdoninput, finalidvvalue, inputActive, masteridvalues, modelRegion, prevOptedAddon, radioValue, rateValue, registeredModelClaimValue, registeredModelOwnershipValue, registeredModelValue, requestSta, rsaInitIdv, tppdVal])


/* ----------------- Date Logics --------------------*/

const userPrevPolicyExp =   registeredModelValues&&registeredModelValues.policyExp

const startDate = new Date(CarIsRegistered ? userPrevPolicyExp : Date.now());
const policyStartDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000); // Add one day in milliseconds
const policyEndDate = new Date(startDate.getTime() + 365 * 24 * 60 * 60 * 1000); // Add one year in milliseconds
const tppolicyEndDate = new Date(
  startDate.getTime() + 3 * 365 * 24 * 60 * 60 * 1000
);
// console.log('date',startDate)
 
/* ---------------- Date Logics End Here ---------------------  */


/* ---------------------- To Update the Policy type value  -------------------------*/
const updatePolicyTypeValueRsa = (value) =>{
  setPolicyType(value)
}

/* -------------------------------------------------------------- */

  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem('jwt_token'));
    };
  
    window.addEventListener('storage', onStorage);
  
    return () => {
        window.removeEventListener('storage', onStorage);
    };
  }, []);


// console.log('isVlue',isValuesRead,carInfo,modelRegionId,masterID,inputStatus)
  useEffect(()=>{
    dispatch(quoteActions.setInputsRead())

    if(Object.keys(carInfo).length>0&&Object.keys(modelRegionId).length>0&&Object.keys(masterID).length>0){
        dispatch(quoteActions.setInputsRead())
        sessionStorage.setItem('inputSet',true)
    }else {
        dispatch(quoteActions.setInputsRead())
        sessionStorage.setItem('inputSet',false)


    }
  },[carInfo, dispatch, masterID, modelRegionId])


  // const playSound = () => {
  //   const audio = new Audio(audioFile); // Replace '/path/to/sound.mp3' with the path to your sound file
  //   audio.play();
  // };
  
  

  useEffect(()=>{
 
    setVehicleNumber(carRegistrationNumber.replace(/^([a-zA-Z]{2})(\d{2})([a-zA-Z]{2})(\d+)$/, "$1-$2-$3-$4"))

  },[carRegistrationNumber])
 
  const fetchPolicyQuoteRsa = ()=>   {
    dispatch(quoteActions.setQuoteLoading(true))
    
    // setTimeout(() => {
    //   // Once the API response comes, play the sound
    //   playSound();
    // }, 2000);


    isValidReq&&  axios
    .post(
      `${API_ENDPOINT}/quotes/rsa`,
      {
        "respType": "JSON",
        "reqType": "JSON",
        "isproductcheck": "No",
        "istranscheck": "No",
        "isNewUser": "No",
        "premium": 0.0,
        "quoteId": "",
        "authenticationDetails": {
            "apikey": "310ZQmv/bYJMYrWQ1iYa7s43084=",
            "agentId": "BA506423"
        },
        "proposerDetails": {
            "strTitle": "",
            "strFirstName": "",
            "strEmail": "",
            "strMobileNo": "",
            "dateOfBirth": "",
            "regCity": "",
            "aadharNumber": "",
            "addressOne": "",
            "addressTwo": "",
            "regPinCode": "",
            "clientCode": "",
            "contactAddress1": "",
            "contactAddress2": "",
            "contactCity": "",
            "contactPincode": "",
            "guardianAge": "",
            "guardianName": "",
            "eiaNumber": "",
            "irName": "",
            "isLoginCheck": "",
            "isNewUser": "",
            "nomineeAge": "",
            "nomineeName": " ",
            "occupation": "",
            "panNumber": "",
            "relationshipwithGuardian": "",
            "relationshipWithNominee": "",
            "same_addr_reg": "",
            "strLastName": "",
            "strPhoneNo": "",
            "strStdCode": "",
            "updatePanAaadharLater": "",
            "userName": "",
            "contactAddress3": "",
            "contactAddress4": "",
            "addressThree": ""
        },
        "vehicleDetails": {
            "yearOfManufacture": manufactureYear, // manufacture Year from step1
            "carRegisteredCity": "",
            "original_idv":   rsaIdvValue ,    // initial idv value from First quote call 
            // "original_idv": finalIDVValue == ("" || null || undefined)? rsaIdvValue:'0',    // initial idv value from First quote call 
            "modify_your_idv": "0",
            "modified_idv_value":finalIDVValue == ("" || null || undefined)
            ? "0"
            : policyType == "thirdParty"
            ? "0"
            : finalIDVValue, // idv addon Value
            "vehicleregDate": defaultRadioValue.registrationDate.split('-').reverse().join('/'), // registration Date
            "previousPolicyExpiryDate": userPrevPolicyExp&& userPrevPolicyExp.split("-").reverse().join("/"), // modal window policyExpir
            "rtoName": modelRegionId.rsa.rtoName, // rto Name
            "vehicleManufacturerName": "",
            "vehicleModelCode":masterID.rsaModelCode[0], //Tbd
            "drivingExperience": "",
            "vehicleRegisteredInTheNameOf": "Individual",
            "companyNameForCar": "",
            "isCarOwnershipChanged":ownership=='Yes'?'Yes': "No",
            "isPreviousPolicyHolder": "true",
            "ProductName": CarIsRegistered ?"RolloverCar":"BrandNewCar", // Rollovercar for old car and new car BrandNewCar
            "typeOfCover":CarIsRegistered?  (policyType=="comprehensive" && "Comprehensive") || (policyType=="thirdParty"&&"LiabilityOnly") || (policyType=="ownDamage"&&"standalone")    : policyType=='bundled'?"Bundled":"LiabilityOnly",// type of policy
            "averageMonthlyMileageRun": "",
            
            "chassisNumber": "",
            "engineCapacityAmount": "",
            "engineNumber": "",
            "fuelType": "",
            "isCarFinanced": "No",
            "isCarFinancedValue": "",
            "financierName": "",
            "policySD":policyStartDate.toLocaleDateString('en-GB'), // same as reliance cover from 
            "policyED":   (!CarIsRegistered&&policyType=='thirdParty' )?tppolicyEndDate.toLocaleDateString("en-GB")
            : policyEndDate
              .toLocaleDateString("en-GB")  , // cover to 
            "policyTerm": "1",
            "region": "",
            "vehicleMostlyDrivenOn": "",
            "vehicleSubLine": "privatePassengerCar",
            "voluntarydeductible":  inputDiscount == ("" || null || undefined)
            ? "0"
            : policyType == "thirdParty"
            ? "0"
            : inputDiscount == "None"
            ? "0"
            : inputDiscount, // discount value
            "modelName": "",
            "modified_idv_2year_value": "0",
            "modified_idv_3year_value": "0",
            "original_idv_2year": "0",
            "original_idv_3year": "0",
            "pucnumber": "",
            "pucvalidUpto": "",
            "technicalDiscount": "",
            "validPUCAvailable": "",
            "VIRNumber": "",
            "registrationNumber": vehicleNumber.toUpperCase().split('-').join(''),// registration number same as Reliance  
            "previousInsurerName": "",
            "previousinsurersCorrectAddress": "",
            "previousPolicyType": CarIsRegistered?registeredModelValues&&registeredModelValues.prevPolicyType : policyType=='bundled'?"Bundled":"LiabilityOnly",
            "previuosPolicyNumber": "",
            "planOpted": "",
            "noClaimBonusPercent":((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?0:`${registeredModelValues&&+registeredModelValues.prevNCB}`, // same as reliance but add +1
            "ncbcurrent": "",
            "ncbprevious": ((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?0:`${registeredModelValues&&+registeredModelValues.prevNCB}`,
            "legalliabilitytopaiddriver": "No", 
            "legalliabilitytoemployees": "No",
            "fibreglass": "no",
            "tppdLimit":tppdChange? 6000:null,

            "towingChargesCover": "no",
            "hdnTyreCover":  finalAddons == null || undefined
            ? false
            : finalAddons.tyreProtection
            ? true
            : false , // tyre protoctor true :false
            "tyreCover":  finalAddons == null || undefined
            ?  "Off"
            : finalAddons.tyreProtection
            ? "On"
            :  "Off" , // tyre protoctor On:Off
            "cpaPolicyTerm": 1,
            "cpaSumInsured": 1500000,
            "cpaCoverisRequired": finalAddons == null || undefined
            ?  "Yes"
            : finalAddons.ownerDriverPAcover
            ? "Yes"
            :  "No", // pa owner driver cover Yes:No
            "accidentcoverforpaiddriver":   finalAddons == ("" || null || undefined)
            ? '0'
            : finalAddons.paidDrivercover
            ? '"200000"'
            : '0',  
            "isValidDrivingLicenseAvailable": "Yes",
            "claimsMadeInPreviousPolicy":  prevClaimValue=='Yes'?'Yes':'No',
            "cover_dri_othr_car_ass": "No",
            "towingChargesCover_SI": "1000",
            "hdtDiscount": 10,
            "personalaccidentcoverforunnamedpassengers": finalAddons == ("" || null || undefined)
            ? '0'
            : finalAddons.unnamedpassengerPAcover
            ? "200000"
            : "0" ,
            "addOnsOptedInPreviousPolicy": finalAddons&& ((finalAddons.zeroDepreciation&&"DepreciationWaiver") ||
          
              (finalAddons.engineProtect&&"AggravationCover") ||
              (finalAddons.consumables&&"consumableCover")),
              "consumableCover":finalAddons&&finalAddons.consumables?"On":"Off",
            "campaignDiscount": "",
            "claimAmountReceived": "0",
            "claimsReported": "0",
            "isBreakinInsurance": "No",
            "isBundleDiscountApply": "No",
            
            "isUsedCar":"No",
            // "isUsedCar": CarIsRegistered ?'Yes': "No",
            "hdnRoadSideAssistanceCover": finalAddons == null || undefined
            ? false
            : finalAddons.roadSideAssistance
            ? true
            : false, // road side assistant True :false
            "roadSideAssistanceCover":finalAddons == null || undefined
            ? "Off"
            : finalAddons.roadSideAssistance
            ? "On"
            : "Off",
            "roadSideAssistancePlan1": finalAddons == null || undefined
            ?  "No"
            : finalAddons.roadSideAssistance
            ? "Yes"
            :  "No", // road side Yes:No
            "roadSideAssistancePlan2": "No",
            
            "isBiFuelKit":('inbuilt','cng').includes(defaultRadioValue.fuelType)    ? 'Yes' :(!["" || null || undefined].includes(finalAddons)&&finalAddons&& finalAddons.externalBiFuelkit)
            ? 'Yes'
            :'No',
            "isBiFuelKitYes":('inbuilt','cng').includes(defaultRadioValue.fuelType) ? "InBuilt" :(!["" || null || undefined].includes(finalAddons)&& finalAddons&&finalAddons.externalBiFuelkit)
            ? 'Add On'
            :'Inbuilt',
            "addonValue":(finalAddonsInput==null||undefined)?0: finalAddonsInput.checkInput3,
            "automobileAssociationMembership": "No",
            "hdnKeyReplacement": finalAddons == null || undefined
            ? false
            : finalAddons.keyLoss
            ? true
            :  false, //keyLoss True : false
            "keyreplacement": finalAddons == null || undefined
            ?  "Off"
            : finalAddons.keyLoss
            ? "On"
            :  "Off", // keyloss On:Off
            "hdnDepreciation": finalAddons == null || undefined
            ? false
            : finalAddons.zeroDepreciation
            ? prevAddons=='true'? true :false
            :  false, // zero Depricia True : false
            "depreciationWaiver": finalAddons == null || undefined
            ?  "Off"
            : finalAddons.zeroDepreciation
            ? prevAddons=='true'?"On":'Off'
            :  "Off", // zer depr is true ? On : Off
            "hdnWindShield": false,
            "windShieldGlass": "Off",
            "hdnProtector": finalAddons == null || undefined
            ?  false
            : finalAddons.engineProtect
            ? true
            :  false, /// engine Protoctor true:false
            "engineprotector": finalAddons == null || undefined
            ?  "Off"
            : finalAddons.engineProtect
            ? "On"
            :  "Off", // engine protoctor On:Off
            "hdnNCBProtector": finalAddons == null || undefined
            ?  false
            : finalAddons.ncbProtection
            ? true
            :  false, // ncb protoctors true:false
            "ncbprotector": finalAddons == null || undefined
            ?  "Off"
            : finalAddons.ncbProtection
            ? "On"
            :  "Off",// ncb protoctors On:Off
            "hdnRoadTax": false,
            "registrationchargesRoadtax": "Off",
            "hdnSpareCar": false,
            "spareCar": "off",
            "spareCarLimit": "",
            "hdnInvoicePrice": '', // returned invoice true:false
            "invoicePrice": '', // returned invoice On:Off
            "hdnLossOfBaggage": finalAddons == null || undefined
            ?  false
            : finalAddons.lossOfPersonalBelongings
            ? true
            :  false, // loss of baggage true : false
            "lossOfBaggage": finalAddons == null || undefined
            ?  "Off"
            : finalAddons.lossOfPersonalBelongings
            ? "On"
            :  "Off", // loss of baggage On:Off
            "valueOfLossOfBaggage": "15000",
            "isVehicleInspected": "No",

            
            "hdnVehicleReplacementCover": finalAddons == null || undefined
            ?  false
            : finalAddons.returntoInvoice
            ? true
            :  false,
  "vehicleReplacementCover":  finalAddons == null || undefined
  ?  "No"
  : finalAddons.returntoInvoice
  ? "Yes"
  :  "No",
  "fullInvoicePrice":  finalAddons == null || undefined
  ?  "No"
  : finalAddons.returntoInvoice
  ? "Yes"
  :  "No",
  "fullInvoicePriceRoadtax": "No",
  "fullInvoicePriceRegCharges": "No",
  "fullInvoicePriceInsuranceCost": "No",
            "cpaCoverDetails": {
              "companyName": "test",
              "cpaCoverWithInternalAgent":!finalAddons.ownerDriverPAcover&& reasonCpaVal=="havingInsured"?true:false,
              "expiryDate": "",
              "noEffectiveDrivingLicense": !finalAddons.ownerDriverPAcover&&reasonCpaVal=="havingLicense"?true:false,
              "policyNumber": "",
              "standalonePAPolicy":!finalAddons.ownerDriverPAcover&& reasonCpaVal=="havingPolicy"?true:false
            },
            "cover_elec_acc": finalAddons == null || undefined
            ?  "No"
            : finalAddons.electricalaccessories
            ? "Yes"
            :  "No", // elect acc Yes:No
            "electricalAccessories": {
                "electronicAccessoriesDetails": [
                    {
                        "makeModel": "",
                        "nameOfElectronicAccessories": "",
                        "value":  finalAddonsInput == null || undefined
                        ? 0
                        : finalAddonsInput&&finalAddonsInput.checkInput1 // elec acc Input1
                    }
                ]
            },
            "valueofelectricalaccessories": finalAddonsInput == null || undefined
            ? 0
            : finalAddonsInput&&finalAddonsInput.checkInput1, // elec acc input1
            "cover_non_elec_acc": finalAddons == null || undefined
            ?  "No"
            : finalAddons.nonElectricalaccessories

            ? "Yes"
            :  "No",  // non elect acc Yes:No
            "nonElectricalAccesories": {
                "nonelectronicAccessoriesDetails": [
                    {
                        "makeModel": "",
                        "nameOfElectronicAccessories": "",
                        "value": finalAddonsInput == null || undefined
                        ? 0
                        : finalAddonsInput&&finalAddonsInput.checkInput2 // non elec Input2
                    }
                ]
            },
            "valueofnonelectricalaccessories": finalAddonsInput == null || undefined
            ? 0
            : finalAddonsInput&&finalAddonsInput.checkInput2  // non elec acc Input2
        },
        "source": "",
        "isPosOpted": "",
        "posCode": "",
        "posDetails": {
            "name": "",
            "pan": "",
            "aadhaar": "",
            "mobile": "",
            "licenceExpiryDate": ""
        },
        "existingTPPolicyDetails": {
            "tpInsurer": "",
            "tpPolicyNumber": "",
            "tpInceptionDate": "",
            "tpExpiryDate": "",
            "tpPolicyTerm": ""
        },
        "isFinalCalculateCall": "No"
       
    },
        
    
      {
        headers: {
          'authorization':jwtToken,
          "Content-Type": "application/json",
          accept: "application/json",
        "x-rsa-type":2
        }
      }
    )
    .then((res) => {
    setIsValidReq(true)

        dispatch(quoteActions.addFirstQuoteLoad(false))
    dispatch(quoteActions.setApiDataValues(res.data.rsaQuoteResponse))
    dispatch(quoteActions.setQuoteLoading(false))
 
    const sessQuoApiVal = sessionStorage.getItem('sessQuoApiVal')
    if(sessQuoApiVal){
      const sessQuoApiValObj = JSON.parse(sessQuoApiVal)
      if(sessQuoApiValObj.rsaQuoteResponse){
        sessQuoApiValObj.rsaQuoteResponse =res.data.rsaQuoteResponse
      }
      sessionStorage.setItem('sessQuoApiVal', JSON.stringify(sessQuoApiValObj))
    }
    // localStorage.setItem('apiData',JSON.stringify(res))
   localStorage.setItem('apiLoaded',true)
   localStorage.setItem('rsaIdvvalue',res.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.IDV)
   
   
    })
   
    .catch((error) =>{

      if(error){
      dispatch(quoteActions.setQuoteLoading(false));
      dispatch(quoteActions.setApiDataValues(null));


      }

    
   
    console.log(error) });
    setIsValidReq(false)
  }

  
  return {fetchPolicyQuoteRsa,updatePolicyTypeValueRsa  };
};


 