import React, { useState, useEffect } from "react";

const RadioPP = ({
  objValues,
  title,
  value,
  errorState = "",
  required = true,
  isCarRadio,
  ClassName='flex flex-col lg:flex-row  space-y-2 lg:space-y-0 lg:space-x-3'
}) => {
  const radioButtons = objValues;
  const [error, setError] = errorState;

  const [isCarRegistered, setisCarRegistered] = useState("");

  useEffect(() => {
    const carRegisterdData = localStorage.getItem("isCarRegistered");

    setisCarRegistered(carRegisterdData);
  }, []);

  // alert(isCarRadio);

  return (
    <div>
      <h1 className="text-[#959595]  ">
        {title}{" "}
        <span className={required ? `text-[#f45138]` : "hidden"}>*</span>
      </h1>
      <div className={` ${ClassName}`}>
        {objValues.map((item, key) => (
          <div
            className={` ${
              key < 1 && false ? "hidden" : "flex"
            }  space-x-1 items-center`}
          >
     

   
  <label  className=" capitalize md:w-max  radio-container pt-3 " htmlFor={item.id} >{item.value}
      <input
        type="radio"
        id={item.id}

        name={item.name}
        checked={item.id === value}
      />
        <span className="checkmark"></span>
        </label>

        
          </div>


        ))}
      </div>
      {error && (
        <h1 className="text-[#b54a39] pt-2 text-sm">Please select an Option</h1>
      )}
    </div>
  );
};

export default RadioPP;
