import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import img from '../assets/Partners/icici-pru.svg'
import { BsMagic } from "react-icons/bs";
import { IoShareSocial } from "react-icons/io5";

const TermPolicyPlanCard = () => {
  const navigate = useNavigate();
  const [isDetailsPopUp, setisDetailsPopUp] = useState(false);
  const [direct, setDirect] = useState(false);

  const formatter = new Intl.NumberFormat('en-IN');

  const handleClick = () => {};

  const logo = 'https://example.com/logo.png';
  const coverAmount = 500000;
  const price = 25000;
  const redirectUrl = '/policy-details';
  const buttonFor = 'term-policy';
  const typeTile = 'Term Policy';
  const logoClass = 'w-32 lg:w-20 lg:h-20';
  const detailsPop = [isDetailsPopUp, setisDetailsPopUp];


  const features = [
    {
        title:"Lumpsum Death Benefit",
        value:'₹ 1.00 Cr'
    },
    {
        title:"Payment / Policy Term",
        value:'53 yrs'
    },
    {
        title:'Claim Settlement Ratio',
        value:'98.7%'
    }
  ]

  return (



    <div
      className={`bg-white relative md:w-[85%] lg:w-[90%] xl:w-[96%] 2xl:w-[98%] card m-auto border border-[rgba(0,167,142,0.5)] pb-6 lg:pb-0 shadow-[8px_8px_36px_rgba(0,167,142,0.18)] rounded-lg mb-6 cursor-pointer mt-7`}
      onClick={handleClick}
    >
      <div className="flex px-4 py-2 items-center">
        {/* Logo */}
        <img
          src={img}
          alt="logo"
          className={`${logoClass}`}
        />
        <div className="ml-auto lg:m-auto">
          {/* <h1 className="text-[#989898] leading-7 text-[10px] xl:text-[14px]">
            Cover Amount (IDV)
          </h1>
          <h2 className="text-center md:text-sm xl:text-base font-bold">
            ₹ {coverAmount.toLocaleString()}
          </h2> */}
        </div>
        <div
          className={`absolute bottom-0 lg:bottom-0 lg:relative flex items-center space-x-7 lg:space-x-4 ml-auto pr-4 mb-2 lg:mb-0`}
        >
          <button
            className="text-hero border-hero   lg:font-semibold border rounded-3xl text-sm lg:text-base  px-5 py-0.5"
            onClick={(e) => {
              setisDetailsPopUp(true);
              localStorage.setItem("tile_Type_Detail", e.target.id);
              sessionStorage.setItem("detail_open", true);
            }}
            id={buttonFor}
          >
            Details
          </button>
          <button
            className="text-white bg-hero flex items-center gap-2 border-hero lg:font-bold    border rounded-3xl text-sm lg:text-base px-5 py-0.5"
            onClick={(e) => {
              localStorage.setItem("tile_Type_price", e.target.id);
              localStorage.setItem("tile_Type_Detail", e.target.id);
              localStorage.setItem("tile_price", price);
              setDirect(true);
              navigate(redirectUrl);
            }}
            id={buttonFor}
          >
            ₹ {formatter.format(price)} <div id={buttonFor} className="text-">
            {"❯"}
            </div>
          </button>
        </div>
      </div>
        <div className="flex items-center gap-2 lg:gap-0 lg:justify-between  mx-4 lg:mx-5 text-sm pb-5">
            {features.map((feature,key)=>(
                <div className="">
                    <h4 className={` ${key<1&&'font-semibold'} text-xs lg:text-base `}>
                        {feature.title}
                    </h4>
                    <p className={`text-[#5b5b5d] text-xs lg:text-base ${key<1&&'font-semibold '} pt-1 `}>
                        {feature.value}
                    </p>
                </div>
            ))}
        </div>

        {/* <div className="mx-5 py-5">
            <h4 className="text-[#2F80ED] text-sm font-semibold">
                View 4 available Options
            </h4>
        </div> */}

        {/* <div className=" flex items-center justify-between mx-5 py-5">
            <div className="flex items-center gap-2">
                <input type="checkbox" />
                <label htmlFor="">Compare</label>
            
            </div>
            <div className="text-hero flex items-center gap-2">
                <BsMagic/>
                <p className="">Benifit Illustration</p>
            </div>

            <div className="flex items-center gap-3">
                <button className="border border-hero flex items-center gap-2 px-3 py-1 rounded-md text-hero w-32 m-auto justify-center">
                    <IoShareSocial/>
                    Share
                </button>
                <button className="  bg-hero text-white rounded-md flex items-center gap-2 px-3 py-1 w-32 m-auto justify-center">
                    View Details
                </button>
            </div>
        </div>  */}
            {/* <div className="flex justify-between items-center px-5 py-5 bg-[#c0c6c63e]">
                <h4 className="text-[#6c7377] text-sm">
                    2 more plan options at  ₹  18,990 -  ₹ 16,324
                </h4>
                <button className='font-medium text-hero'>Quick Compare</button>
            </div> */}
    </div>
  );
};

export default TermPolicyPlanCard;