import React, { useState, useEffect } from "react";
import LandingBackground from "../../../assets/Svg/LandingPageBck.svg";
import { Link, useNavigate } from "react-router-dom";
import NavHeader from "../../NavHeader";
import InputPP from "../../customs/InputPP";
import { useDispatch } from "react-redux";
import { updateField } from "../../../store/slices/term-insurance-slice";

const TermIntro3 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [closeNav, setCloseNav] = useState(false);
  const [inputs, setInputs] = useState({
    name: "",
    mobileNo: "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);

  const termsStep3Values = localStorage.getItem("tm_step_3")

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length === 0) {
      setShowError(false);
      localStorage.setItem("tm_step_3",JSON.stringify(inputs))
      dispatch(updateField({ field: 'firstName', value: inputs.name }));
      navigate("/termInsurance/quote");
    } else {
      setErrors(validationErrors);
      setShowError(true);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "", // Clear error when user starts typing
    }));
  };

  const validateInputs = () => {
    const validationErrors = {};
    if (!inputs.name) {
      validationErrors.name = "Name is required";
    }  
    if (!inputs.mobileNo) {
      validationErrors.mobileNo = "Mobile number is required";
    } else if (!/^\d{10}$/.test(inputs.mobileNo)) {
      validationErrors.mobileNo = "Mobile number must be a 10-digit number";
    }
    if (!inputs.email) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(inputs.email)) {
      validationErrors.email = "Enter a valid email address";
    }
    return validationErrors;
  };

  useEffect(() => {
   if(termsStep3Values) {
    setInputs(JSON.parse(termsStep3Values));
   }
  }, [termsStep3Values]);

  console.log('inuts',inputs)

  return (
    <>
      <NavHeader dropClose={[closeNav, setCloseNav]} />
      <main
        className="w-full pb-10 lg:pt-8 bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${LandingBackground})` }}
        onClick={() => setCloseNav(false)}
      >
        <form onSubmit={handleSubmit} onChange={handleInputChange}>
          <h1 className="text-xl lg:text-3xl text-[#293651] font-semibold py-10 lg:pt-4 text-center">
            Term Insurance Quote
          </h1>

          {/* Steps Bar */}
          <div className="lg:w-[55%] rounded-t-md bg-[#aab8aa27] m-auto">
            <div className="lg:w-[99%] rounded-t-md bg-hero py-[3px]"></div>
          </div>

          <div className="justify-center bg-white mx-5 lg:m-auto shadow-[0px_0px_25px_rgba(0,0,0,0.1)] lg:drop-shadow-lg lg:w-[55%] h -[500px] px-4 py-5">
            <h1 className="mr-auto font-medium text-solid lg:text-xl py-4 pl-4">
              Secure Your Quote Now
            </h1>
            <p className="text-sm py-2 pl-4">
              Saved quotes will be sent to you via email or SMS
            </p>
            <hr className="text-[#DBDBDB] pb-4" />

            <div className="py-4 space-y-4 w-full">
              <InputPP
                className={`w-full lg:w-80 px-3 py-2 my-2 rounded-md ${
                  errors.name ? "border-[#fc573d] " : ""
                }`}
                labelClassName="text-[#959595] font-medium pl-1"
                id="name"
                label="Name"
                type="text"
                placeholder="Enter your name"
                value={inputs.name}
                onChange={handleInputChange}
                errorMessage={errors.name}
              />
              {/* {errors.name && (
                <p className="text-red-500 text-xs pl-1">{errors.name}</p>
              )} */}

              <InputPP
                className={`w-full lg:w-80 px-3 py-2 my-2 rounded-md ${
                  errors.mobileNo ? "border-[#fc573d]" : ""
                }`}
                labelClassName="text-[#959595] font-medium pl-1"
                id="mobileNo"
                label="Mobile Number"
                type="number"
                maxLength="10"
                placeholder="Enter your mobile number"
                value={inputs.mobileNo}
                onChange={handleInputChange}
                errorMessage={errors.mobileNo}
              />
              {/* {errors.mobileNo && (
                <p className="text-red-500 text-xs pl-1">{errors.mobileNo}</p>
              )} */}

              <InputPP
                className={`w-full lg:w-80 px-3 py-2 my-2 rounded-md ${
                  errors.email ? "border-[#fc573d]" : ""
                }`}
                labelClassName="text-[#959595] font-medium pl-1"
                id="email"
                label="Email"
                type="email"
                placeholder="Enter your email"
                value={inputs.email}
                onChange={handleInputChange}
                errorMessage={errors.email}
              />
              {/* {errors.email && (
                <p className="text-red-500 text-xs pl-1">{errors.email}</p>
              )} */}
            </div>

            {showError && (
              <h1 className="text-center pt-2 text-sm text-[#eb5648]">
                Please fill all required fields
              </h1>
            )}
          </div>

          <div className="flex justify-center space-x-5 pt-8">
            <Link to="/terminsurance/term-profile/prestep-2">
              <button className="bg-[#293651] w-24 py-2 rounded-full text-white">
                Back
              </button>
            </Link>
            <button
              type="submit"
              className="bg-hero w-24 py-2 rounded-full text-white cursor-pointer"
            >
              Next
            </button>
          </div>
        </form>
      </main>
    </>
  );
};

export default TermIntro3;
