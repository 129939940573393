import React, { useState, useEffect } from "react";
import LandingBackground from "../../../assets/Svg/LandingPageBck.svg";
import { Link, useNavigate } from "react-router-dom";
import NavHeader from "../../NavHeader";
import DropDownPP from "../../customs/DropDownPP";
import { useDispatch } from "react-redux";
import { updateField } from "../../../store/slices/term-insurance-slice";

const TermIntro2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [closeNav, setCloseNav] = useState(false);
  const [inputs, setInputs] = useState({
    occupation: "",
    education: "",
    annualIncome: "",
    sumInsured: "",
  });
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);

  const termStep2Values = localStorage.getItem("tm_step_2")

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length === 0) {
      setShowError(false);
      localStorage.setItem('tm_step_2',JSON.stringify(inputs))
      dispatch(updateField({ field: 'income', value: inputs.annualIncome }));
      navigate("/terminsurance/term-profile/prestep-3");
    } else {
      setErrors(validationErrors);
      setShowError(true);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const validateInputs = () => {
    const validationErrors = {};
    if (!inputs.occupation) validationErrors.occupation = "Occupation is required";
    if (!inputs.education) validationErrors.education = "Education level is required";
    if (!inputs.annualIncome) validationErrors.annualIncome = "Annual income is required";
    if (!inputs.sumInsured) validationErrors.sumInsured = "Sum insured amount is required";
    return validationErrors;
  };

  useEffect(() => {
if(termStep2Values) {
  setInputs(JSON.parse(termStep2Values))
}    
  }, [termStep2Values]);

  

  return (
    <>
      <NavHeader dropClose={[closeNav, setCloseNav]} />
      <main
        className="w-full pb-10 lg:pt-8 bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${LandingBackground})` }}
        onClick={() => setCloseNav(false)}
      >
        <form onSubmit={handleSubmit} onChange={handleInputChange}>
          <h1 className="text-xl lg:text-3xl text-[#293651] font-semibold py-10 lg:pt-4 text-center">
            Term Insurance Quote
          </h1>

          {/* Steps Bar */}
          <div className="lg:w-[55%] rounded-t-md bg-[#aab8aa27] m-auto">
            <div className="lg:w-[66%] rounded-t-md bg-hero py-[3px]"></div>
          </div>

          <div className="justify-center bg-white mx-5 lg:m-auto shadow-[0px_0px_25px_rgba(0,0,0,0.1)] lg:drop-shadow-lg lg:w-[55%] h -[500px] px-4 py-5">
            <h1 className="mr-auto font-medium text-solid lg:text-xl py-4 pl-4">
              Professional Details of Insured
            </h1>
            <hr className="text-[#DBDBDB] pb-4" />

            <div className="py-4 space-y-4 w-full">
              {["occupation", "education", "annualIncome", "sumInsured"].map((field, index) => (
                <DropDownPP
                  key={index}
                  className="w-full lg:w-96 border border-[#d7d5d5] rounded-md focus:outline-none px-3 py-2.5 my-2"
                  labelClassName="text-[#959595] font-medium pl-1 w-20"
                  label={getLabel(field)}
                  id={field}
                  value={inputs[field]}
                  onChange={handleInputChange}
                  options={getOptions(field)}
                  errorMessage={errors[field]}
                />
              ))}
            </div>

            {showError && (
              <h1 className="text-center pt-2 text-sm text-[#eb5648]">
                Please fill all required fields
              </h1>
            )}
          </div>

          <div className="flex justify-center space-x-5 pt-8">
            <Link to="/terminsurance/term-profile/prestep-1">
              <button className="bg-[#293651] w-24 py-2 rounded-full text-white">
                Back
              </button>
            </Link>
            <button
              type="submit"
              className="bg-hero w-24 py-2 rounded-full text-white cursor-pointer"
            >
              Next
            </button>
          </div>
        </form>
      </main>
    </>
  );
};

export default TermIntro2;

// Helper functions to get dropdown label and options
const getLabel = (field) => {
  const labels = {
    occupation: "Occupation",
    education: "Educational Qualification",
    annualIncome: "Approx. Annual Income",
    sumInsured: "Sum Assured Amount",
  };
  return labels[field];
};

const getOptions = (field) => {
  const options = {
    occupation: ["Salaried", "Self employed"],
    education: ["Graduate and above", "12th pass", "10th pass", "Below 10th"],
    annualIncome: ["Less than 3 Lac", "3 Lac to 5 Lac", "5 Lac to 7 Lac", "7 Lac to 10 Lac", "10 Lac to 15 Lac", "15 Lac+"],
    sumInsured: ["10 Lac", "20 Lac", "30 Lac", "40 Lac", "50 Lac+"],
  };
  return options[field];
};
