import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputPP from "../customs/InputPP";
import RadioPP from "../customs/RadioPP";
import NavHeader from "../NavHeader";
import LandingBackground from "../../assets/Svg/LandingPageBck.svg";
import { carProposalAction } from "../../store/slices/car-proposal";
import {useDispatch, useSelector } from "react-redux";

import DropDownPP from "../customs/DropDownPP";
import { insurerName } from "../../data/PreviousPolicyInsurer";
import InsuranceHeader from "../../UI/insurance/InsuranceHeader";
import InsuranceTracker from "../../UI/insurance/InsuranceTracker";
import axios from "axios";
import BikeInsuranceHeader from "../../UI/insurance/BikeInsuranceHeader";
import BikeInsuranceTracker from "../../UI/insurance/BikeInsuranceTracker";

const BikeInfoStep4 = ({ proposalData, dataLoaded }) => {
  //Metro cities in India
  const metroCities = ["Kolkata", "Chennai", "Delhi", "Mumbai"];

  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    vehicleOwnership:'yes',
    bikeRegisterNo:'',
    bikeRegistrationDate:'',
    // financedValue:'100'
  });
  const [proceed, setProceed] = useState(false);
  const [bikeRegisterNoError, setBikeRegisterNoError] = useState(false);
  const [engineNoError, setEngineNoError] = useState(false);
  const [chasisNoError, setChasisNoError] = useState(false);
  const [bikeRegisterDateError, setBikeRegisterDateError] = useState(false);
  const [prevPolicyInsurerError, setPrevPolicyInsurerError] = useState(false);
  const [prevPolicyNoError, setPrevPolicyNoError] = useState(false);
  const [tpPolicyNumberError, setTpPolicyNumberError] = useState(false);
  const [exisTPExpDateError, setExisTPExpDateError] = useState(false);
  const [bikeLoanError, setBikeLoanError] = useState(false);
  const [load, setLoad] = useState(false);
  const [rsaKycApiData, setRsaKycApiData] = useState()
  const [inputIdvValue, setInputIdvValue] = useState()

  const [financierNameError, setFinancierNameError] = useState(false);
  const [financierCityError, setFinancierCityError] = useState(false);
  const [financierAddress, setFinancierAddress] = useState(false);
  const [extKitError, setExtKitError] = useState(false);
  const [jwtToken, setJwtToken] = useState(localStorage.getItem("jwt_token"));
  const [pincodeDetails, setPincodeDetails] = useState({});
  const [step1Values, setStep1Values] = useState({});
  const [bikeRegistrationNumber, setBikeRegistrationNumber] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [rtoRegionCode, setRtoRegionCode] = useState("");
  const [manufactureYear, setManufactureYear] = useState();
  const [normalBikeRegNo, setNormalBikeRegNo] = useState();
  const [initialPolicyType, setInitialPolicyType] = useState({});
  const [productCodeValue, setProductCodeValue] = useState(false);
  const [financedValue, setFinancedValue] = useState(false)

  const [step2Values, setStep2Values] = useState({});
  const [step3Values, setStep3Values] = useState({});
  const [step4Values, setStep4Values] = useState({});
  const [registeredModelValues, setRegisteredModelValues] = useState(null);
  const [modelRegionId, setModelRegionId] = useState({});
  const [popUpsFilterData, setPopUpsFilterData] = useState({});
  const [prevClaimValue, setPrevClaimValue] = useState();
  const [idvValue, setIdvValue] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();
  const [isBikeRegistered, setIsBikeRegistered] = useState('true');
  const [tileType, setTileType] = useState();
  const [popData, setPopData] = useState();
  const [ratesValues, setRatesValues] = useState();
  const [tppdChange, setTppdChange] = useState(true)

  const [finalAddons, setFinalAddons] = useState({});
  const [tpNewDate, setTpNewDate] = useState();
  const [finalAddonsInput, setFinalAddonsInput] = useState({});
  const [vehicleOwnershipError, setvehicleOwnershipError] = useState(false);
  const [inputDiscount, setInputDiscount] = useState();
  const [manufactureMonth, setManufactureMonth] = useState();
  const [masterID, setMasterID] = useState({});
  const [previousInsurerId, setPreviousInsurerId] = useState({});
  const [previousInsurerId2, setPreviousInsurerId2] = useState({});
  const [prevInsurerData, setPrevInsurerData] = useState([]);
  const [prevInsurerData2, setPrevInsurerData2] = useState([]);
  const [loadAgain, setLoadAgain] = useState(false);
  const [isPopWindowOpen, setIsPopWindowOpen] = useState(false);
  const [closeNav, setCloseNav] = useState(false);
  const [makeErrorTrue, setMakeErrorTrue] = useState(false);
  const [godigitKycDetails, setGodigitKycDetails] = useState([]);
  const [ownership, setOwnership] = useState()

  const [prevTpInsurerError, setPrevTpInsurerError] = useState(false)


  const [prevTpInsurerId, setPrevTpInsurerId] = useState({})




  const bikeRegistered = localStorage.getItem("isBikeRegistered");
  const filterData = sessionStorage.getItem("bike_inputs_policyType");
  const godigitKycData = localStorage.getItem("godigitKycInput");
  let registeredModelValue = localStorage.getItem("bike_registered_model");
  let registeredModelOwnershipValue= localStorage.getItem('bike_registered_model_ownership')

  const bikeOwnerDetails = localStorage.getItem("bike_step-1_Values");
  const personalDetails = localStorage.getItem("bike_step-2_Values");
  const addressDetails = localStorage.getItem("bike_step-3_Values");
  const vechileDetails = localStorage.getItem("bike_step-4_Values");
  const pincodeData = localStorage.getItem("bike_pincode_Details");
  const insurerId = localStorage.getItem("bike_insurance_Id");
  const radioValue = localStorage.getItem("bike_Info");
  let registeredModelClaimValue = localStorage.getItem(
    "bike_registered_model_claim"
  );
  const bikeRegNO = localStorage.getItem("bike_Intro");
const rsaKycApi = localStorage.getItem('rsaKyc_apidata')
const inputIdvVal = sessionStorage.getItem('bike_inputs_IDV')

  const modelRegion = localStorage.getItem("bike_regionCode");
  const tileValue = localStorage.getItem("tile_Type_Detail");
  const apidata = localStorage.getItem("apiData");
  const finaladdon = sessionStorage.getItem("bike_input_addons_check");
  const finaladdoninput = sessionStorage.getItem("bike_check_inputs");
  const finalDiscount = sessionStorage.getItem("bike_inputs_discount");
  const masteridvalues = localStorage.getItem("bike_master_ID");
  const prevPolicyHolderNames = localStorage.getItem("prev_policy_names");
  const rateValue = localStorage.getItem("ratesValues");
  const tppdVal = sessionStorage.getItem('bike_tppdVal')

  const [proposalApiData, setProposalApiData] = proposalData;
  const [isDataLoaded, seTisDataLoaded] = dataLoaded;
  /* -------------------  API ENDPOINT -------------- */
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  /* ---------------------------------- -------------- */
  const dispatch = useDispatch();

  // useEffect(()=>{
  //   if(Object.values(masterID).length>0){
  //     setLoad(true)
  //   }
  // },[masterID])

  useEffect(() => {
    setTileType(tileValue);
    setPopData(JSON.parse(apidata));
  }, [tileValue, apidata]);

  useEffect(() => {
    setIdvValue(
      (tileType == "Reliance" &&
        popData.data.relianceQuoteResponse.MotorPolicy.IDV) ||
        (tileType == "GoDigit" &&
          popData.data.goDigitQuoteResponse.vehicle.vehicleIDV.idv) ||
        (tileType == "RSA" &&
          popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.IDV)
    );
  }, [popData, tileType]);
  useEffect(() => {
    setPopUpsFilterData(filterData);
    setRegisteredModelValues(JSON.parse(registeredModelValue));
    setPrevClaimValue(registeredModelClaimValue);
    bikeRegNO !== null
      ? setBikeRegistrationNumber(JSON.parse(bikeRegNO).registrationNumber)
      : setBikeRegistrationNumber(inputs.bikeRegisterNo);

    setManufactureYear(JSON.parse(radioValue).manufactureYear);
    bikeRegNO && setNormalBikeRegNo(JSON.parse(bikeRegNO).registrationNumber);
    setInitialPolicyType(JSON.parse(radioValue));
    setPincodeDetails(JSON.parse(pincodeData));
    setRsaKycApiData(JSON.parse(rsaKycApi))
    setOwnership(registeredModelOwnershipValue)
    setInputIdvValue(inputIdvVal)

    setModelRegionId(JSON.parse(modelRegion));
    setStep1Values(JSON.parse(bikeOwnerDetails));
    setStep2Values(JSON.parse(personalDetails));
    setStep3Values(JSON.parse(addressDetails));
    setStep4Values(JSON.parse(vechileDetails));
    setFinalAddonsInput(JSON.parse(finaladdoninput));
    setInputDiscount(finalDiscount);
    setMasterID(JSON.parse(masteridvalues));
    setPrevInsurerData2(JSON.parse(prevPolicyHolderNames));
    setGodigitKycDetails(JSON.parse(godigitKycData));
    setFinalAddons(JSON.parse(finaladdon));
    // load&&setPreviousInsurerId2(JSON.parse(insurerId))
    setRatesValues(JSON.parse(rateValue));
    setTppdChange(tppdVal)

    setIsBikeRegistered(bikeRegistered);
  }, [bikeOwnerDetails, load, rateValue, inputs, inputIdvVal, godigitKycData, prevPolicyHolderNames, finalDiscount, insurerId, masteridvalues, finaladdoninput, bikeRegistered, finaladdon, modelRegion, pincodeData, radioValue, bikeRegNO, registeredModelClaimValue, vechileDetails, filterData, registeredModelValue, addressDetails, personalDetails, rsaKycApi, registeredModelOwnershipValue, tppdVal]);

  console.log('bikereg',tileType)

  
  useEffect(() => {
    /* ------------ For Getting Prev Insurer Names ---------------- */
    tileType&&  axios
      .get(`${API_ENDPOINT}/previousInsurer/listOfPrevIns?company=${tileType}`, {
        headers: {
          Authorization: `${jwtToken}`,
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        // const values = [];
        // response.data.map((item) =>
        //   values.push(item.standardPreviousInsurerName)
        // );
        setPrevInsurerData2(response.data);
        localStorage.setItem('prev_policy_names',JSON.stringify(response.data))
        // response.data.standardPreviousInsurerName.map((item)=>(
        setLoadAgain(true);
        //   (item)
        // ))
      })
      .catch((err) => {
        console.log(err);
      });
  }, [API_ENDPOINT, jwtToken, tileType]);
  useEffect(() => {
    /* ---------------- For Getting ID's of Previous Insurer -------------- */
    Object.keys(inputs).includes("prevPolicyInsurer") &&
      axios
        .get(`${API_ENDPOINT}/insurance/id?name=${inputs.prevPolicyInsurer}&companyName=${tileValue}`, {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json"
          }
        })
        .then((response) => {
          localStorage.setItem(
            "bike_insurance_Id",
            JSON.stringify(response.data[0])
          );
          setPreviousInsurerId(response.data[0]);
          setLoad(true);
        })
        .catch((err) => {
          console.log(err);
        });



        Object.keys(inputs).includes("prevTpInsurer") &&
        axios
          .get(`${API_ENDPOINT}/insurance/id?name=${inputs.prevTpInsurer}&companyName=${tileValue}`, {
            headers: {
              Authorization: `${jwtToken}`,
              "Content-Type": "application/json"
            }
          })
          .then((response) => {
            localStorage.setItem(
              "bike_insurance-Tp_Id",
              JSON.stringify(response.data[0])
            );
            setPrevTpInsurerId(response.data[0]);
            setLoad(true);
          })
          .catch((err) => {
            console.log(err);
          });
  

    // https://staging-policymart.co.in/api/v1/insurance/id?name=
  }, [inputs]);



  useEffect(() => {
    const vehicleNumberHandle = () => {
      let vehNo = [""];
      let spliting = bikeRegistrationNumber && bikeRegistrationNumber.split("");
      vehNo.unshift(spliting);
      bikeRegistrationNumber &&
        setVehicleNumber(
          (bikeRegistrationNumber && bikeRegistrationNumber.length) < 10
            ? vehNo[0][0] +
                vehNo[0][1] +
                "-" +
                vehNo[0][2] +
                vehNo[0][3] +
                "-" +
                vehNo[0][4] +
                "-" +
                vehNo[0][5] +
                vehNo[0][6] +
                vehNo[0][7] +
                vehNo[0][8]
            : vehNo[0][0] +
                vehNo[0][1] +
                "-" +
                vehNo[0][2] +
                vehNo[0][3] +
                "-" +
                vehNo[0][4] +
                vehNo[0][5] +
                "-" +
                vehNo[0][6] +
                vehNo[0][7] +
                vehNo[0][8] +
                vehNo[0][9]
        );
      bikeRegistrationNumber &&
        setRtoRegionCode(vehNo[0][0] + vehNo[0][1] + vehNo[0][2] + vehNo[0][3]);
    };
    vehicleNumberHandle();
  }, [bikeRegistrationNumber]);

  useEffect(() => {
    const vehicleNumberHandle = () => {
      let dob = [""];
      let spliting = step2Values.dateOfBirth.split("-");
      dob.unshift(spliting);
      setDateOfBirth(dob[0][2] + "/" + dob[0][1] + "/" + dob[0][0]);
    };
    step2Values.dateOfBirth && vehicleNumberHandle();
  }, [step2Values.dateOfBirth]);

  useEffect(() => {
    if (popUpsFilterData && popUpsFilterData.length > 0) {
      setProductCodeValue(true);
    } else {
      setProductCodeValue(false);
    }
  }, [popUpsFilterData]);

  useEffect(() => {
    if (
      Object.keys(inputs).includes("bikeRegisterNo") &&
      inputs.bikeRegisterNo.length < 1
    ) {
      setBikeRegisterNoError(true);
    } else if (
      Object.keys(inputs).includes("bikeRegisterNo") &&
      inputs.bikeRegisterNo.length > 0
    ) {
      setBikeRegisterNoError(false);
    }
    if (
      Object.keys(inputs).includes("engineNo") &&
      inputs.engineNo.length < 1
    ) {
      setEngineNoError(true);
    } else if (
      Object.keys(inputs).includes("engineNo") &&
      inputs.engineNo.length > 0
    ) {
      setEngineNoError(false);
    }
    if (
      Object.keys(inputs).includes("chasisNo") &&
      inputs.chasisNo.length < 1
    ) {
      setChasisNoError(true);
    } else if (
      Object.keys(inputs).includes("chasisNo") &&
      inputs.chasisNo.length > 0
    ) {
      setChasisNoError(false);
    }
    
    if (
      Object.keys(inputs).includes("prevPolicyNo") &&
      inputs.prevPolicyNo.length < 1
    ) {
      setPrevPolicyNoError(true);
    } else if (
      Object.keys(inputs).includes("prevPolicyNo") &&
      inputs.prevPolicyNo.length > 0
    ) {
      setPrevPolicyNoError(false);
    }
    if (
      Object.keys(inputs).includes("tpPolicyNumber") &&
      inputs.tpPolicyNumber.length < 1
    ) {
      setTpPolicyNumberError(true);
    } else if (
      Object.keys(inputs).includes("tpPolicyNumber") &&
      inputs.tpPolicyNumber.length > 0
    ) {
      setTpPolicyNumberError(false);
    }
    if (
      Object.keys(inputs).includes("financierName") &&
      inputs.financierName.length < 1
    ) {
      setFinancierNameError(true);
    } else if (
      Object.keys(inputs).includes("financierName") &&
      inputs.financierName.length > 0
    ) {
      setFinancierNameError(false);
    }
    if (
      Object.keys(inputs).includes("financierCity") &&
      inputs.financierCity.length < 1
    ) {
      setFinancierCityError(true);
    } else if (
      Object.keys(inputs).includes("financierCity") &&
      inputs.financierCity.length > 0
    ) {
      setFinancierCityError(false);
    }
    if (
      Object.keys(inputs).includes("financierAddress") &&
      inputs.financierAddress.length < 1
    ) {
      setFinancierAddress(true);
    } else if (
      Object.keys(inputs).includes("financierAddress") &&
      inputs.financierAddress.length > 0
    ) {
      setFinancierAddress(false);
    }
    // if (
    //   Object.keys(inputs).includes("financedValue") &&
    //   inputs.financierName.length < 1
    // ) {
    //   setFinancedValue(true);
    // } else if (
    //   Object.keys(inputs).includes("financedValue") &&
    //   inputs.financierName.length > 0
    // ) {
    //   setFinancedValue(false);
    // }
    // if (
    //   Object.keys(inputs).includes("vehicleOwnership") &&
    //   inputs.vehicleOwnership.length < 1
    // ) {
    //   setvehicleOwnershipError(true);
    // } else if (
    //   Object.keys(inputs).includes("vehicleOwnership") &&
    //   inputs.vehicleOwnership.length > 0
    // ) {
    //   setvehicleOwnershipError(false);
    // }
  }, [inputs]);

  const userEnterDate = new Date(
    registeredModelValues && registeredModelValues.policyExp
  );
  // let prevDay = userEnterDate.getDate();
  // let prevvDay = userEnterDate.getDate() + 1;

  // let prevMonth = userEnterDate.getMonth() + 1;
  // let prevYear = userEnterDate.getFullYear();
  let prevplusYear = userEnterDate.getFullYear() + 1;
  // let prevActDay = userEnterDate.getDate();
  // let tpPrevYear = userEnterDate.getFullYear() - 1;

  var dateFormatTotime = new Date(userEnterDate);
  var increasedDate = new Date(dateFormatTotime.getTime() + 1 * 86400000);

  // var dateString = "Mon Jun 30 2014 00:00:00";

  var startDate = new Date(
    registeredModelValues && registeredModelValues.policyExp
  );

  // seconds * minutes * hours * milliseconds = 1 day
  var day = 60 * 60 * 24 * 1000;

  var endDate = new Date(startDate.getTime() + day);

  /* ------------------ Manufacture Month ----------------------- */
  const manufacturemonth = new Date(inputs.bikeRegistrationDate);
  let bikeMonth = manufacturemonth.getMonth() + 1;
  if (bikeMonth < 10) {
    bikeMonth = "0" + bikeMonth;
  }

  useEffect(() => {
    setIsBikeRegistered(bikeRegistered);
  }, [bikeRegistered]);

  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]: name === "loan" ||name==='vehicleOwnership' || name === "externalKit" ? e.target.id : value
    }));
  };
  // generating Date for tpnewDate
  // useEffect(()=>{

  //   const generateNeedDate =(input)=>{

  //     return setTpNewDate(`${cYear}-${cMonth}-${cDay}`)
  //   }
  // },[step4Values])
  // generateNeedDate(step4Values.existingTPExpDate)

  /* For Generating +1 Day  for Cover from and Cover To Start Here for New vehicle */


  let currentDate ;

  // Add logic to set the date based on a condition
  if (isBikeRegistered==='true' ) {
    currentDate=(new Date(registeredModelValues&&registeredModelValues.policyExp) ); // Add one day
  } else if (isBikeRegistered==='false') {
    currentDate=(new Date() ); // Add two days
  }
  let cDay =currentDate&& currentDate.getDate() + 1;
  let endCDay =currentDate&& currentDate.getDate();

  let cMonth =currentDate&& currentDate.getMonth() + 1;
  let cYear = currentDate&&currentDate.getFullYear();

  if ((cYear + 1) % 4 === 0 && endCDay == 28) {
    endCDay += 1;
  }

  if (cMonth === 11) {
    cMonth = 0;
    cYear += 1;
  } else if (cMonth === 1) {
    let febDays =
      cYear % 4 === 0 && (cYear % 100 !== 0 || cYear % 400 === 0) ? 29 : 28;
    if (cDay === febDays) {
      cDay = 0;
      cMonth += 1;
    }
  } else {
    if (cDay === new Date(cYear, cMonth + 1, 0).getDate()) {
      cDay = 0;
      cMonth += 1;
    }
  }
  let nextDateNew = new Date(cYear, cMonth + 1, cDay + 1);

  let newMonth1New = nextDateNew.getMonth();
  /* For Generating +1 Day  for Cover from and Cover To Start Here for New vehicle */



 /* ----------------------------------------------------- */
 const startDates = new Date(currentDate);
 startDates.setDate(currentDate&&currentDate.getDate()+1);
 
 // Calculate the end date (coverTo) as the same day next year, adjusting for leap years
 const nextYear =currentDate&& currentDate.getFullYear() + 1;
 
 
 /* ----------------------------------- */
 function getDaysInFebruaryNextYear() {
   const currentYear = new Date().getFullYear();
   const nextYear = currentYear + 1;
 
   // Create a date for March 1st of the next year
   const marchFirstNextYear = new Date(nextYear, 2, 1);
 
   // Subtract one day to get the last day of February in the next year
   const lastDayOfFebruaryNextYear = new Date(marchFirstNextYear - 1);
 
   // Get the day of the month to determine the number of days in February
   return lastDayOfFebruaryNextYear.getDate();
 }
 
 const daysInFebruaryNextYear = getDaysInFebruaryNextYear();
 /* ------------------------------------------------ */
 
 
 const isNextYearLeap = new Date(nextYear, 1, 29).getDate() === 29;
 const endDates = isNextYearLeap && nextYear%4===0&&daysInFebruaryNextYear===29&&currentDate&&currentDate.getMonth()===1
   ? new Date(nextYear, 1, 29)
   : new Date(nextYear, currentDate&&currentDate.getMonth(), currentDate&&currentDate.getDate() );
 // Format the dates as DD-MM-YYYY
 const formattedStartDate = formatDate(startDates);
 const formattedEndDate = formatDate(endDates);
 
 // Function to format date as DD-MM-YYYY
 function formatDate(date) {
   const day = String(date.getDate()).padStart(2, '0');
   const month = String(date.getMonth() + 1).padStart(2, '0');
   const year = date.getFullYear();
   return `${day}-${month}-${year}`;
 }
 
 
 /* -------------------------------- */

  /* For Generating +1 Day  for Cover from and Cover To Start Here */

  // let newCyear = currentDate.getFullYear() + 1;
  if (cDay < 10) {
    cDay = "0" + cDay;
  }
  if (cMonth < 10) {
    cMonth = "0" + cMonth;
  }
  let day1 = userEnterDate.getDate();
  let month1 = userEnterDate.getMonth();
  let year1 = userEnterDate.getFullYear();

  if (month1 === 11) {
    month1 = 0;
    year1 += 1;
  } else if (month1 === 1) {
    let febDays =
      year1 % 4 === 0 && (year1 % 100 !== 0 || year1 % 400 === 0) ? 29 : 28;
    if (day1 === febDays) {
      day1 = 0;
      month1 += 1;
    }
  } else {
    if (day1 === new Date(year1, month1 + 1, 0).getDate()) {
      day1 = 0;
      month1 += 1;
    }
  }
  let nextDate = new Date(year1, month1 + 1, day1 + 1);


  let newMonth1 = nextDate.getMonth();

/* ---------------- Date Function For Old Vehicle start Here ---------------------  */


let dateString =registeredModelValues ? registeredModelValues.policyExp.split("-").reverse().join('-'):'2023-1-5'
// let dateString ='2023-1-5'
let dateParts = dateString.split("-");
let yearAdd1 = parseInt(dateParts[2]);
let monthAdd1 = parseInt(dateParts[1]) - 1;
let dayAdd1 = parseInt(dateParts[0]);

let dateAdd1 = new Date(yearAdd1, monthAdd1, dayAdd1);
dateAdd1.setDate(dateAdd1.getDate() + 1);

let newYear = dateAdd1.getFullYear();
let newMonth = String(dateAdd1.getMonth() + 1).padStart(2, "0");;
let newDay =  String(dateAdd1.getDate()).padStart(2, "0");

let newDateString = `${newDay}-${newMonth}-${newYear}`;
/* +1 year */

let dateCt1 = new Date(`${yearAdd1+1}-${monthAdd1+1}-${dayAdd1}`);
dateCt1.setDate(dateCt1.getDate());

 
let newCtYear = dateCt1.getFullYear();
let newCtMonth = String(dateCt1.getMonth() + 1).padStart(2, "0");;
let newCtDay =  String(dateCt1.getDate()).padStart(2, "0");

let newCtDateString = `${newCtDay}-${newCtMonth}-${newCtYear}`;

/* ------------------- */

/* -1 day */

let dateRem1 = new Date(`${newYear-1}-${newMonth}-${newDay}`);

dateRem1.setDate(dateRem1.getDate() -1);

 
let newRemYear = dateRem1.getFullYear();
let newRemMonth = String(dateRem1.getMonth() + 1).padStart(2, "0");;
let newRemDay =  String(dateRem1.getDate()).padStart(2, "0");

let newRemDateString = `${newRemDay}-${newRemMonth}-${newRemYear}`;


/* ---------------- Date Function For Old Vehicle End Here ---------------------  */

/* ---------------- Date Function For New Vehicle start Here ---------------------  */



/* ---------------- Date Function For New Vehicle End Here ---------------------  */

/* --------------------------------------------------------------------- */
  /* For Generating +1 Day  for Cover from and Cover To End Here */

  var date = new Date(inputs.existingTPExpDate);
  let tpDay = date.getDate();
  let tpMonth = date.getMonth() + 1;
  let tpYear = date.getFullYear() - 3;
  if (tpDay < 10) {
    tpDay = "0" + tpDay;
  }
  if (tpMonth < 10) {
    tpMonth = "0" + tpMonth;
  }
  date.setFullYear(date.getFullYear() + 1);


  let prevDay = userEnterDate.getDate();
  let prevvDay = userEnterDate.getDate() + 1;

  let prevMonth = userEnterDate.getMonth() + 1;
  let prevYear = userEnterDate.getFullYear();
  let prevActDay = userEnterDate.getDate();
  let tpPrevYear = userEnterDate.getFullYear() - 1;

  if ((prevYear + 1) % 4 === 0 && prevDay == 28) {
    prevDay += 1;
  }

  var dateFormatTotime = new Date(userEnterDate);
  var increasedDate = new Date(dateFormatTotime.getTime() + 1 * 86400000);

  // var dateString = "Mon Jun 30 2014 00:00:00";

  var startDate = new Date(
    registeredModelValues && registeredModelValues.policyExp
  );

  // seconds * minutes * hours * milliseconds = 1 day
  // var day = 60 * 60 * 24 * 1000;

  var endDate = new Date(startDate.getTime() + day);
  // var toCurDate = new Date(currentDate.getTime() + day);
  var fromPrevDay = new Date(userEnterDate.getTime() + day);
  // console.log("dash",
  //  !bikeRegisterNoError && !engineNoError &&!chasisNoError && !prevPolicyNoError &&!financierNameError && !financierCityError && !financierAddress&& navigate(proceed ? "/bikeinsurance/bike-profile/preview" : ""));

  useEffect(() => {
    if (
      (Object.keys(inputs).includes("bikeRegisterNo") || makeErrorTrue) &&
      (inputs.bikeRegisterNo == undefined || inputs.bikeRegisterNo == "")
    ) {
      setBikeRegisterNoError(true);
    } else {
      setBikeRegisterNoError(false);
    }
    if (
      (Object.keys(inputs).includes("engineNo") || makeErrorTrue) &&
      (/^[a-zA-Z0-9]*$/.test(inputs.engineNo) == false ||
        inputs.engineNo == undefined ||
        inputs.engineNo == "")
    ) {
      setEngineNoError(true);
    } else {
      setEngineNoError(false);
    }
    if (
      (Object.keys(inputs).includes("chasisNo") || makeErrorTrue) &&
      ((/^[a-zA-Z0-9]*$/.test(inputs.chasisNo) == false || inputs.chasisNo) ==
        undefined ||
        inputs.chasisNo == "")
    ) {
      setChasisNoError(true);
    } else {
      setChasisNoError(false);
    }
    if (
      (Object.keys(inputs).includes("bikeRegistrationDate") || makeErrorTrue) &&
      (inputs.bikeRegistrationDate == undefined ||
        inputs.bikeRegistrationDate == "")
    ) {
      setBikeRegisterDateError(true);
    } else {
      setBikeRegisterDateError(false);
    }
    if (
      (Object.keys(inputs).includes("prevPolicyInsurer") || makeErrorTrue) &&
      (inputs.prevPolicyInsurer == undefined || inputs.prevPolicyInsurer == "")
    ) {
      setPrevPolicyInsurerError(true);
    } else {
      setPrevPolicyInsurerError(false);
    }
    if (
      (Object.keys(inputs).includes("prevPolicyNo") || makeErrorTrue) &&
      ((/^[a-zA-Z0-9- /]*$/.test(inputs.prevPolicyNo) == false ||
        inputs.prevPolicyNo) == undefined ||
        inputs.prevPolicyNo == "")
    ) {
      setPrevPolicyNoError(true);
    } else {
      setPrevPolicyNoError(false);
    }
    if (
      (Object.keys(inputs).includes("existingTPExpDate") || makeErrorTrue) &&
      (inputs.existingTPExpDate == undefined || inputs.existingTPExpDate == "")
    ) {
      setExisTPExpDateError(true);
    } else {
      setExisTPExpDateError(false);
    }
    if (
      (Object.keys(inputs).includes("prevTpInsurer") || makeErrorTrue) &&
      (inputs.prevTpInsurer == undefined || inputs.prevTpInsurer == "")
    ) {
      setPrevTpInsurerError(true);
    } else {
      setPrevTpInsurerError(false);
    }
    if (
      (Object.keys(inputs).includes("tpPolicyNumber") || makeErrorTrue) &&
      (inputs.tpPolicyNumber == undefined || inputs.tpPolicyNumber == "")
    ) {
      (popUpsFilterData == (undefined || null)
        ? initialPolicyType.policy == "ownDamage"
        : popUpsFilterData == "ownDamage") && setTpPolicyNumberError(true);
    } else {
      setTpPolicyNumberError(false);
    }
    if (
      (Object.keys(inputs).includes("loan") || makeErrorTrue) &&
      (inputs.loan == undefined || inputs.loan == "")
    ) {
      setBikeLoanError(true);
    } else {
      setBikeLoanError(false);
    }
    if (
      (Object.keys(inputs).includes("financierName") ||
        makeErrorTrue ||
        inputs.loan == "yes") &&
      (inputs.financierName == undefined || inputs.financierName == "")
    ) {
      setFinancierNameError(true);
    } else {
      setFinancierNameError(false);
    }
    // if (
    //   (Object.keys(inputs).includes("financedValue") ||
    //     makeErrorTrue ||
    //     inputs.loan == "yes"||tileType == "RSA" )&&
    //   (inputs.financedValue == undefined || inputs.financedValue == "")
    // ) {
    //   setFinancedValue(true);
    // } else {
    //   setFinancedValue(false);
    // }
    if (
      (Object.keys(inputs).includes("financiercity") ||
        makeErrorTrue ||
        inputs.loan == "yes") &&
      (inputs.financiercity == undefined || inputs.financiercity == "")
    ) {
      setFinancierCityError(true);
    } else {
      setFinancierCityError(false);
    }
    if (
      (Object.keys(inputs).includes("financierAddress") ||
        makeErrorTrue ||
        inputs.loan == "yes") &&
      (inputs.financierAddress == undefined || inputs.financierAddress == "")
    ) {
      setFinancierAddress(true);
    } else {
      setFinancierAddress(false);
    }

    // if (
    //   (Object.keys(inputs).includes("vehicleOwnership") || makeErrorTrue) &&
    //   (inputs.vehicleOwnership == undefined || inputs.vehicleOwnership == "")
    // ) {
    //   setvehicleOwnershipError(true);
    // } else {
    //   setvehicleOwnershipError(false);
    // }
    // if (
    //   (Object.keys(inputs).includes("externalKit") || makeErrorTrue) &&
    //   (inputs.externalKit == undefined || inputs.externalKit == "")
    // ) {
    //   setExtKitError(true);
    // } else {
    //   setExtKitError(false);
    // }
  }, [initialPolicyType.policy, inputs, makeErrorTrue, popUpsFilterData]);


  
  const handleSubmit = (e) => {
    e.preventDefault();

dispatch(carProposalAction.setApiFailed(false));

sessionStorage.setItem('godigitSta','NOT_DONE')

    setMakeErrorTrue(true);

    (isBikeRegistered == "true"
      ? !bikeRegisterNoError &&
        !engineNoError &&
        !chasisNoError &&
        !prevPolicyNoError &&
        ((
          popUpsFilterData == (undefined || null)
            ? initialPolicyType.policy == "ownDamage"
            : popUpsFilterData == "ownDamage"
        )
          ? !tpPolicyNumberError
          : true) &&
        (inputs.loan == "yes"
          ? !financierNameError && (tileType == "RSA"?true:true)&& !financierCityError && !financierAddress
          : true)
      : !bikeRegisterNoError &&
        !engineNoError &&
        !chasisNoError &&
        (inputs.loan == "yes"
          ? !financierNameError &&(tileType == "RSA"?true:true) && !financierCityError && !financierAddress
          : true)) &&
          // (tileType == "RSA"?!vehicleOwnershipError:true)&&
      navigate(proceed ?tileType=="GoDigit"? "/bikeinsurance/bike-profile/step-5":'/bikeinsurance/bike-profile/preview' : "");


    localStorage.setItem("bike_payment_Data", JSON.stringify({}));
    seTisDataLoaded(false);
    proceed&&tileType == "Reliance" &&
      axios
        .post(
          `${API_ENDPOINT}/proposal/reliance`,
          //
          {
            productCode:
                          isBikeRegistered == "true" || isBikeRegistered == true
                            ? (productCodeValue
                                ? popUpsFilterData == "comprehensive" && 2312
                                : initialPolicyType.policy == "comprehensive" && 2312) ||
                              ((productCodeValue
                                ? popUpsFilterData == "thirdParty" && 2348
                                : initialPolicyType.policy == "thirdParty") &&
                                2348) ||
                              (productCodeValue
                                ? popUpsFilterData == "ownDamage" && 2308
                                : initialPolicyType.policy == "ownDamage" && 2308)
                            : (productCodeValue
                                ? popUpsFilterData == "bundled" && 2375
                                : initialPolicyType.policy == "bundled" && 2375) ||
                              (productCodeValue
                                ? popUpsFilterData == "thirdParty" && 2370
                                : initialPolicyType.policy == "thirdParty" && 2370), //based on vehicle type
            
            clientDetails: {
              // "clientType": 0,
              clientType: step1Values.registered == "yes" ? 0 : 0, // registered in company : yes ?1:0

              // "lastName": "deshmukh",
              lastName: step1Values.lastName,
              // "foreName": "priyanka",
              foreName: step1Values.firstName,
              dob: dateOfBirth,
              // "dob": "01/01/1991",
              // "gender": 2,
              gender: step2Values.gender == "male" ? 1 : 2,
              mobileNo: step1Values.mobile,
              // "mobileNo": step1Values.mobile,
              // "clientAddress":
              // {
              //   "communicationAddress":
              //    {
              //     "addressType": 0,
              //     "address1": 'fsadfdsf',
              //   "address2": "agggaaa",
              //   "address3": "strifffffng",
              //   "cityId":pincodeDetails.cityOrVillageId, // cityid

              //   "districtId":pincodeDetails.districtId,
              //   "stateId": pincodeDetails.stateId, // state id

              //   // "pinCode": '400006',
              //   "pinCode":  step3Values.pincode,

              //   "country": "India"
              // },
              // "permanentAddress": {
              //   "addressType": 0,
              //   "address1": 'step3Values.address',
              //   "address2": "aaaffa",
              //   "address3": "strifffffng",
              //   "cityId":pincodeDetails.cityOrVillageId, // cityid

              //   "districtId":pincodeDetails.districtId,
              //   "stateId": pincodeDetails.stateId, // state id

              //   // "pinCode": '400006',
              //   "pinCode":  step3Values.pincode,

              //   "country": "India"
              // },
              // "registrationAddress": {
              //   "addressType": 0,
              //   // "address1": "Bungalow No. 101",
              //   "address1":'chinthaguda',
              //   "address2": "aaddaa",
              //   "address3": "strifffffng",
              //   "cityId":pincodeDetails.cityOrVillageId, // cityid

              //   "districtId":pincodeDetails.districtId,
              //   "stateId": pincodeDetails.stateId, // state id

              //   // "pinCode": '400006',
              //   "pinCode":  step3Values.pincode,

              //   "country": "India"
              // }
              // },
              clientAddress: {
                communicationAddress: {
                  addressType: 0,
                  address1: step3Values.address,
                  address2: step3Values.address2,
                  address3: step3Values.address2,
                  cityId: pincodeDetails.cityOrVillageId,
                  districtId: pincodeDetails.districtId,
                  stateId: pincodeDetails.stateId,
                  pincode: step3Values.pincode,
                  country: "India"
                },
                permanentAddress: {
                  addressType: 0,
                  address1: step3Values.address,
                  address2: step3Values.address2,
                  address3: step3Values.address2,
                  cityId: pincodeDetails.cityOrVillageId,
                  districtId: pincodeDetails.districtId,
                  stateId: pincodeDetails.stateId,
                  pincode: step3Values.pincode,
                  country: "India"
                },
                registrationAddress: {
                  addressType: 0,
                  address1: step3Values.address,
                  address2: step3Values.address2,
                  address3: step3Values.address2,
                  cityId: pincodeDetails.cityOrVillageId,
                  districtId: pincodeDetails.districtId,
                  stateId: pincodeDetails.stateId,
                  pinCode: step3Values.pincode,
                  country: "India"
                }
              },
              // "emailId": "priyanka.deshmukh@relianceada.com",
              emailId: step1Values.email,
              // "salutation": "Mrs.",
              salutation: `${step1Values.salutation}.`,
              // "maritalStatus": "1951",
              maritalStatus: step2Values.married == "yes" ? "1951" : "1952",
              // registeredUnderGst: step1Values.registered == "yes" ? 1 : 0,
              // "gstin": "27AASCS2460H1Z0"
              gstin: null
            },
            policy: {
              // "tpPolicyNumber": "PB9867786234554321",
              tpPolicyNumber: (
                popUpsFilterData == (undefined || null)
                  ? initialPolicyType.policy == "ownDamage"
                  : popUpsFilterData == "ownDamage"
              )
                ? step4Values.tpPolicyNumber
                : inputs.prevPolicyNo,

              tpPolicyStartDate: `${tpYear}-${tpMonth}-${tpDay}`,
              // "tpPolicyEndDate": "2024-08-21",
              tpPolicyEndDate:
                Object.keys(inputs).includes("existingTPExpDate") &&
                inputs.existingTPExpDate.split().reverse().join(),
              tpPolicyInsurer: prevTpInsurerId.relianceId,
              // "businessType": "5",
              businessType:
                isBikeRegistered == "true" || isBikeRegistered == true
                  ? "5"
                  : "1",
              // "coverFrom": "24/12/2022",
              "coverFrom":    formattedStartDate.split("-").reverse().join('-'), // user inpuut 
     
              "coverTo":  formattedEndDate.split("-").reverse().join('-'), // -1 day from input
              // "coverTo": '2024-02-29' , // -1 day from input
              // coverFrom:
              //   isBikeRegistered == "true" || isBikeRegistered == true
              //     ? (endDate.getDate() < 10
              //         ? `0${endDate.getDate()}`
              //         : endDate.getDate()) +
              //       "/" +
              //       (prevMonth < 10 ? `0${prevMonth}` : prevMonth) +
              //       "/" +
              //       prevYear
              //     : cDay + "/" + cMonth + "/" + cYear,
              // // "coverTo": "24/12/2023",  /// same as quote
              // coverTo:
              //   isBikeRegistered == "true" || isBikeRegistered == true
              //     ? (prevDay < 10 ? `0${prevDay}` : prevDay) +
              //       "/" +
              //       (prevMonth < 10 ? `0${prevMonth}` : prevMonth) +
              //       "/" +
              //       prevplusYear
              //     : cDay + "/" + cMonth + "/" + newCyear,

              branchCode: "9202",
              agentName: "Direct",
              // "productCode": "2311",
           
              productCode:
                            isBikeRegistered == "true" || isBikeRegistered == true
                              ? (productCodeValue
                                  ? popUpsFilterData == "comprehensive" && 2312
                                  : initialPolicyType.policy == "comprehensive" && 2312) ||
                                ((productCodeValue
                                  ? popUpsFilterData == "thirdParty" && 2348
                                  : initialPolicyType.policy == "thirdParty") &&
                                  2348) ||
                                (productCodeValue
                                  ? popUpsFilterData == "ownDamage" && 2308
                                  : initialPolicyType.policy == "ownDamage" && 2308)
                              : (productCodeValue
                                  ? popUpsFilterData == "bundled" && 2375
                                  : initialPolicyType.policy == "bundled" && 2375) ||
                                (productCodeValue
                                  ? popUpsFilterData == "thirdParty" && 2370
                                  : initialPolicyType.policy == "thirdParty" && 2370), //based on vehicle type
              
              otherSystemName: "1",
              isMotorQuote: "false"
            },
            risk: {
              financierCity: inputs.financiercity,
              exShowroomPrice: "",
              chassis: inputs.chasisNo,
              financeType: inputs.loan == "yes" ? 3 : 2,
              // stateOfRegistrationID: 21, // pincode api
              stateOfRegistrationID: modelRegionId.reliance.stateId, // pincode api
              isHavingValidDrivingLicense: true,
              isOptedStandaloneCPAPolicy:
                finalAddons && finalAddons.ownerDriverPAcover ? false : true,
              // "vehicleVariant": "LXI CNG",
              // "zone":'A',
              zone: modelRegionId.reliance.modelZoneName, // use region rto zone
              financierAddress: inputs.financierAddress,
              rto_RegionCode:modelRegionId&&modelRegionId.reliance.regionCode.replace("-", ""),

              // "cubicCapacity": 998, //not required
              manufactureYear: manufactureYear,
              vehicleMakeID: masterID.reliance[0].makeId, // master id make id
              financierName: inputs.financierName,
              isPermanentAddressSameasCommAddress: true,
              isVehicleHypothicated: inputs.loan == "yes" ? true : false,
              isRegAddressSameasPermanentAddress: true,
              vehicleModelID: masterID.reliance[0].modelId, // master is model id
              isRegAddressSameasCommAddress: true,
              manufactureMonth: bikeMonth, // bike registration date do
              // "dateOfPurchase": "2019-07-01",
              dateOfPurchase: inputs.bikeRegistrationDate,
              engineNo: inputs.engineNo,
              // rtolocationID: 571, // api regioncode modelRegionId
              rtolocationID: modelRegionId.reliance.modelRegionId, // api regioncode modelRegionId
               idv: +idvValue // idv quote
              // idv: 873348.0
            },

            vehicle: {
              seatingCapacity: 2,
              // registrationNumber: vehicleNumber.toUpperCase(),
              registrationNumber: isBikeRegistered=='true'? vehicleNumber.toUpperCase():`NEW`,
              registrationDate: inputs.bikeRegistrationDate,
              typeOfFuel:
                (initialPolicyType.fuelType == "petrol" && 1) ||
                (initialPolicyType.fuelType == "diesel" && 2) ||
                (initialPolicyType.fuelType ==
                  ("petrol+cng" || "cng" || "lpg" || "petrol+lpg") &&
                  5) ||
                (initialPolicyType.fuelType == "electric" && 6)
              // typeOfFuel: "1"
            },
            cover: {
              isBasicODCoverage:
                (popUpsFilterData && popUpsFilterData == "thirdParty") ||
                (initialPolicyType && initialPolicyType.policy == "thirdParty")
                  ? false
                  : true,
              lstTaxComponentDetails: "",

              basicLiability:
                (popUpsFilterData && popUpsFilterData == "ownDamage") ||
                (initialPolicyType && initialPolicyType.policy == "ownDamage")
                  ? false
                  : true,

              isVoluntaryDeductableOpted:
                inputDiscount == ("" || null || undefined)
                  ? false
                  : inputs.policyType == "thirdParty"
                  ? false
                  : inputDiscount == "None"
                  ? false
                  : inputDiscount == "None"
                  ? false
                  : true, // dicut addons
              voluntaryDeductible: {
                voluntaryDeductible: {
                  sumInsured:
                    inputDiscount == ("" || null || undefined)
                      ? "0"
                      : inputs.policyType == "thirdParty"
                      ? "0"
                      : inputDiscount == "None"
                      ? "0"
                      : inputDiscount /// discount value
                }
              },

              // "sumInsured": "7500",
              isAntiTheftDeviceFitted: false,
              antiTheftDeviceDiscount: {
                antiTheftDeviceDiscount: {
                  packageName: "",
                  isMandatory: true,
                  noOfItems: "1",
                  isChecked: true
                }
              },
              isPAToOwnerDriverCoverd:
                finalAddons == ("" || null || undefined)
                  ? false
                  : finalAddons.ownerDriverPAcover
                  ? true
                  : false,
              paCoverToOwner: {
                // owner pa driver from addons
                paCoverToOwner: {
                  nomineeName: step2Values.nomineeName, // nominee name from 2nd step
                  nomineeDob: step2Values.nomineedateOfBirth, // nominee dob from 2nd form
                  nomineeRelationship: step2Values.relation, // from 2nd form
                  appointeeName: step2Values.appointeeName,
                  cpaCovertenure: "1",
                  nomineeAddress: ""
                }
              },
              isPAToDriverCovered:
                finalAddons == ("" || null || undefined)
                  ? false
                  : finalAddons.paidDrivercover
                  ? true
                  : false,

              paToPaidDriver: {
                paToPaidDriver: {
                  noOfItems: 1,
                  sumInsured: 200000
                }
              },
              isPAToNamedPassenger: false,
              paToNamedPassenger: {
                paToNamedPassenger: {
                  noOfItems: "3",
                  sumInsured: "100000",
                  isMandatory: false,
                  isChecked: false,
                  passengerName: "1",
                  nomineeName: "2",
                  nomineeDob: "3",
                  nomineeRelationship: "4",
                  nomineeAddress: "5",
                  otherRelation: "6",
                  appointeeName: "7",
                  packageName: "8"
                }
              }, // false
              isPAToUnnamedPassengerCovered:
                finalAddons == ("" || null || undefined)
                  ? false
                  : finalAddons.unnamedpassengerPAcover
                  ? true
                  : false, // addons unnamed
              paToUnNamedPassenger: {
                paToUnNamedPassenger: {
                  noOfItems: "2",
                  sumInsured: "100000"
                }
              },

              isLiabilityToPaidDriverCovered: false,
              liabilityToPaidDriver: {
                liabilityToPaidDriver: {
                  noOfItems: "5"
                } // false
              },
              isTppdCover:
                (popUpsFilterData && popUpsFilterData == "ownDamage") ||
                (initialPolicyType && initialPolicyType.policy == "ownDamage")
                  ? false
                  : true,
              tppdCover: {
                tppdCover: {
                  sumInsured: "6000",
                  isChecked:
                    (popUpsFilterData && popUpsFilterData == "ownDamage") ||
                    (initialPolicyType &&
                      initialPolicyType.policy == "ownDamage")
                      ? false
                      : true
                }
              },

              isNilDepreciation: false, // from addon

                  nilDepreciationCoverage: {
                                    nilDepreciationCoverage: {
                                      applicableRate: "0.6"
                                    }
                                  },
              // nilDepreciationCoverage: {
              //   nilDepreciationCoverage: {
              //     applicableRate: ratesValues && ratesValues[0]
              //   }
              // },
              isSecurePlus: false, // true or false from addons
              securePlus: {
                securePlus: {
                  applicableRate:0.5
                }
              },
              isSecurePremium: false, // true or false
              securePremium: {
                securePremium: {
                  applicableRate: 0
                }
              },
              // "isBiFuelKit":inputs.externalKit=='externalKityes'? true:false,

              // isBiFuelKit: true,
              isBiFuelKit:
                initialPolicyType.fuelType == ("petrol+cng" || "petrol+lpg")
                  ? true
                  : false,
              // isBiFuelKit:
              //   finalAddons == null || undefined
              //     ? false
              //     :   finalAddons.externalBiFuelkit
              //     ? false
              //     : false,
              bifuelKit: {
                bifuelKit: {
                  sumInsured:
                    0, // from addons bifuel input

                  // islpgCng: true,
                  islpgCng:
                    initialPolicyType.fuelType == ("petrol+cng" || "petrol+lpg")
                      ? true
                      : false,
                  policyCoverDetailsID: {},
                  // isChecked: true,
                  isChecked:
                    initialPolicyType.fuelType == ("petrol+cng" || "petrol+lpg")
                      ? true
                      : false,
                  fueltype:
                    initialPolicyType.fuelType == ("petrol+cng" || "cng")
                      ? "CNG"
                      : "LPG",
                  isMandatory: false
                }
              },

              // "biFuelKit": initialPolicyType.fuelType == ('petrol+cng'||'petrol+lpg')?true:false,//
              // "newVehicle":  false, // this need to be cleared
              // "basicODCoverage":oldPolicyType=="thirdParty"?false: true,
              // "biFuelIsChecked": initialPolicyType.fuelType == ('petrol+cng'||'petrol+lpg')?true:false,//
              // "biFuelIsMandatory": false,//
              // "biFuelIsLpgCng": initialPolicyType.fuelType == ('petrol+cng'||'petrol+lpg')?true:false, //
              // "biFuelSumInsured": 100.0,
              // "biFuelFuelType": initialPolicyType.fuelType == 'petrol+cng'?"CNG":"LPG",//

              //   "electricItemFitted":false, //
              //   "isPAToOwnerDriverCoverd":false,
              //    "nonElectricalItemFitted":false,
              //  "basicLiability":false

              electricItems: "",
              nonElectricItems: "",

              electricalItemFitted:false, // based on addon api ele,
              electricalItemsTotalSI:0, // adddon ele input value

              nonElectricalItemFitted: false, //based on addons api nonel false ;true,
              nonElectricalItemsTotalSI:0 // addons none ele inut value
            },

            previousInsuranceDetails: {
              // "prevYearInsurer":'2',
              prevYearInsurer: previousInsurerId.relianceId,
              isPreviousPolicyDetailsAvailable: "true",
              prevYearInsurerAddress: "",
              prevYearPolicyType: "1",
              prevYearPolicyNo: inputs.prevPolicyNo,
              // prevYearPolicyStartDate:'01/03/2022',

              prevYearPolicyStartDate:
              `${newDay}/${newMonth}/${newRemYear}`,
              prevYearPolicyEndDate:
              `${newRemDay}/${newRemMonth}/${newYear}`
            },

            ncbEligibility: {
              currentNcb:
                (registeredModelValues &&
                  registeredModelValues.prevNCB == (null || undefined)) ||
                prevClaimValue == "Yes"
                  ? 0
                  : `${
                      registeredModelValues &&
                      +registeredModelValues.prevNCB
                    }`,
              ncbeligibilityCriteria: prevClaimValue == "Yes" ? "1" : "2",
              previousNcb:
                (registeredModelValues &&
                  registeredModelValues.prevNCB == (null || undefined)) ||
                prevClaimValue == "Yes"
                  ? 0
                  : registeredModelValues && registeredModelValues.prevNCB
            },
            //  "productcode": "2311",
            userId: "100002",
            sourceSystemId: "100002",
            authToken: "Pass@123",
            existingRGICustomer: false
          },

          {
            headers: {
              authorization: jwtToken,
              "Content-Type": "application/json",
              accept: "application/json",
              "x-rsa-type": 1
            }
          }
        )
        .then((res) => {
          // setApiData(res);
          setProposalApiData(res.data.MotorPolicy);
          seTisDataLoaded(true);
          localStorage.setItem(
            "bike_payment_Data",
            JSON.stringify(res.data.MotorPolicy)
          );
          localStorage.setItem("proposal_loaded", true);
          dispatch(carProposalAction.setApiFailed(false));

        })

        .catch((error) => {
          dispatch(carProposalAction.setApiFailed(true));

          console.log(error)});

    /* ---------------- Proposal For Godigit ------------------ */

    proceed&&  tileType == "GoDigit" &&
      axios
        .post(
          `${API_ENDPOINT}/proposal/godigit`,
          //
          {
            enquiryId: "GODIGIT_CQ_TWO_WHEELER_PACKAGE_01",
            contract: {
              insuranceProductCode:
                isBikeRegistered == "true" || isBikeRegistered == true
                  ? (productCodeValue
                      ? popUpsFilterData == "comprehensive" && 20201
                      : initialPolicyType.policy == "comprehensive" && 20201) ||
                    (productCodeValue
                      ? popUpsFilterData == "thirdParty" && 20202
                      : initialPolicyType.policy == "thirdParty" && 20202) ||
                    (productCodeValue
                      ? popUpsFilterData == "ownDamage" && 20203
                      : initialPolicyType.policy == "ownDamage" && 20203)
                  : (productCodeValue
                      ? popUpsFilterData == "bundled" && 20201
                      : initialPolicyType.policy == "bundled" && 20201) ||
                    (productCodeValue
                      ? popUpsFilterData == "thirdParty" && 20202
                      : initialPolicyType.policy == "thirdParty" && 20202), // product code
              subInsuranceProductCode:
                isBikeRegistered == "true" || isBikeRegistered == true
                  ? "PB"
                  : initialPolicyType.policy == "bundled"
                  ? 51
                  : 50, // pb
                  startDate:  formattedStartDate.split("-").reverse().join('-'), // cover from
                  endDate: formattedEndDate.split("-").reverse().join('-'), 
              // "startDate":null,
              // "endDate":null,

              policyHolderType: "INDIVIDUAL",
              externalPolicyNumber: "PARTNER_POL_NO",
              isNCBTransfer: null,
              coverages: {
                voluntaryDeductible:
                  inputDiscount == null || undefined
                    ? "ZERO"
                    : inputDiscount == "None"
                    ? "ZERO"
                    : inputs.policyType == "thirdParty"
                    ? "ZERO"
                    : ((inputDiscount=='1000'&&'THOUSAND')   ||  (inputDiscount=='2000'&&'TWO_THOUSAND')|| (inputDiscount=="2500" && "TWENTYFIVE_HUNDRED") || (inputDiscount=='3000'&&'THREE_THOUSAND')), // disc addon
                thirdPartyLiability: { 
                  isTPPD:     
                  
                  (productCodeValue==undefined||null? (['thirdParty',"ownDamage"]).includes(popUpsFilterData) ?false: true : (['thirdParty',"ownDamage"]).includes(initialPolicyType&&initialPolicyType.policy) ?false: true  )
                  //
                },
                ownDamage: {
                  discount: {
                    userSpecialDiscountPercent: 0,
                    discounts: []
                  },
                  surcharge: {
                    loadings: []
                  }
                },
                personalAccident: {
                  selection:
                    finalAddons == ("" || null || undefined)
                      ? false
                      : finalAddons.ownerDriverPAcover
                      ? true
                      : false, // pa owner driver true
                  coverTerm: 1,
                  coverAvailability: "AVAILABLE",
                  netPremium: "INR 0.00"
                },
                accessories: {
                  cng: {
                    selection:false, // bifuel true
                    insuredAmount: 0
                         // bifuel input
                  },
                  electrical: {
                    selection:
                     false, // electric
                    insuredAmount:0 // electric input
                  },
                  nonElectrical: {
                    selection:  false, // non electric
                    insuredAmount: 0
                        // non electric Input
                  }
                },
                addons: {
                  partsDepreciation: {
                    claimsCovered: "ONE",
                    selection: false // zero dep true
                  },
                  roadSideAssistance: {
                    selection:false // road
                  },
                  engineProtection: {
                    selection: false /// engine
                  },
                  tyreProtection: {
                    selection: false // tyre
                  },
                  rimProtection: {
                    selection: null
                  },
                  returnToInvoice: {
                    selection: false // return
                  },
                  consumables: {
                    selection: false // consu
                  }
                },
                legalLiability: {
                  paidDriverLL: {
                    selection: null,
                    insuredCount: null
                  },
                  employeesLL: {
                    selection: null,
                    insuredCount: null
                  },
                  unnamedPaxLL: {
                    selection: null,
                    insuredCount: null
                  },
                  cleanersLL: {
                    selection: null,
                    insuredCount: null
                  },
                  nonFarePaxLL: {
                    selection: null,
                    insuredCount: null
                  },
                  workersCompensationLL: {
                    selection: null,
                    insuredCount: null
                  }
                },
                unnamedPA: {
                  unnamedPax: {
                    selection:
                      finalAddons == null || undefined
                        ? false
                        : finalAddons.unnamedpassengerPAcover
                        ? true
                        : false, // unnamed passweneger cover
                    insuredAmount: null,
                    insuredCount: null
                  },
                  unnamedPaidDriver: {
                    selection:
                      finalAddons == null || undefined
                        ? false
                        : finalAddons.paidDrivercover
                        ? true
                        : false, // paid driver
                    insuredAmount: null,
                    insuredCount: null
                  },
                  unnamedHirer: {
                    selection: null,
                    insuredAmount: null,
                    insuredCount: null
                  },
                  unnamedPillionRider: {
                    selection: null,
                    insuredAmount: null,
                    insuredCount: null
                  },
                  unnamedCleaner: {
                    selection: null,
                    insuredAmount: null,
                    insuredCount: null
                  },
                  unnamedConductor: {
                    selection: null,
                    insuredAmount: null,
                    insuredCount: null
                  }
                }
              }
            },
            vehicle: {
              isVehicleNew:
                isBikeRegistered == "true" || isBikeRegistered == true
                  ? false
                  : true, ///  new vehicle false :::: Error if i make True
              vehicleMaincode: masterID.goDigitVehicleCode[0], // master main code
              licensePlateNumber:
                isBikeRegistered == "true" || isBikeRegistered == true
                  ? vehicleNumber.replaceAll("-", "").toUpperCase()
                  : `${modelRegionId.reliance.regionCode.replace("-", "")}`, // num plate
              vehicleIdentificationNumber:
                isBikeRegistered == "true" || isBikeRegistered == true
                  ? null
                  : "ABC12345678911111",
              engineNumber: inputs.engineNo, // engine
              "manufactureDate":  inputs.bikeRegistrationDate, // registration date
              // manufactureDate: null, // registration date
              registrationDate: inputs.bikeRegistrationDate, // registration date
              "vehicleIDV": {

                "idv": +inputIdvValue,
                "defaultIdv": null,
                "minimumIdv": popData&&popData.data.goDigitQuoteResponse.vehicle.vehicleIDV.minimumIdv,
                "maximumIdv": popData&&popData.data.goDigitQuoteResponse.vehicle.vehicleIDV.maximumIdv,
                },
              usageType: null,
              permitType: null,
              motorType: null
            },
            previousInsurer: {
              isPreviousInsurerKnown:  isBikeRegistered == "true" || isBikeRegistered == true
              ? true:false,
              previousInsurerCode: previousInsurerId.goDigitCode, // prev comp code
              previousPolicyNumber: inputs.prevPolicyNo, // prev policy num
              previousPolicyExpiryDate:
                prevYear + "-" + prevMonth + "-" + prevActDay, // prev expiry date
              isClaimInLastYear: prevClaimValue == "Yes" ? true : false, // model popup
              originalPreviousPolicyType:  isBikeRegistered == "true" || isBikeRegistered == true
              ? (productCodeValue
                  ? popUpsFilterData == "comprehensive" && "1OD_5TP"
                  : initialPolicyType.policy == "comprehensive" && "1OD_5TP") ||
                (productCodeValue
                  ? popUpsFilterData == "thirdParty" && "1OD_5TP"
                  : initialPolicyType.policy == "thirdParty" && "1OD_5TP") ||
                (productCodeValue
                  ? popUpsFilterData == "ownDamage" && "1OD_5TP"
                  : initialPolicyType.policy == "ownDamage" && "1OD_5TP")
              : (productCodeValue
                  ? popUpsFilterData == "bundled" && "1OD_5TP"
                  : initialPolicyType.policy == "bundled" && "1OD_5TP") ||
                (productCodeValue
                  ? popUpsFilterData == "thirdParty" &&  "1OD_5TP"
                  : initialPolicyType.policy == "thirdParty" &&  "1OD_5TP"),


              previousPolicyType:  isBikeRegistered == "true" || isBikeRegistered == true
              ? (productCodeValue
                  ? popUpsFilterData == "comprehensive" &&  "1OD_1TP"
                  : initialPolicyType.policy == "comprehensive" &&  "1OD_1TP") ||
                (productCodeValue
                  ? popUpsFilterData == "thirdParty" &&  "0OD_1TP"
                  : initialPolicyType.policy == "thirdParty" &&  "0OD_1TP") ||
                (productCodeValue
                  ? popUpsFilterData == "ownDamage" && "1OD_0TP"
                  : initialPolicyType.policy == "ownDamage" && "1OD_0TP")
              : (productCodeValue
                  ? popUpsFilterData == "bundled" &&  "1OD_1TP"
                  : initialPolicyType.policy == "bundled" &&  "1OD_1TP") ||
                (productCodeValue
                  ? popUpsFilterData == "thirdParty" &&  "0OD_1TP"
                  : initialPolicyType.policy == "thirdParty" &&  "0OD_1TP"),
                  "previousNoClaimBonus": isBikeRegistered == "true" || isBikeRegistered == true?  ((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?0:`${registeredModelValues&&+registeredModelValues.prevNCB}`:'0', /// pop up ncb
                  "currentThirdPartyPolicy": {
                    "currentThirdPartyPolicyInsurerCode": null,
                    "currentThirdPartyPolicyNumber": inputs.tpPolicyNumber,
                    "currentThirdPartyPolicyStartDateTime": inputs.bikeRegistrationDate,
                    "currentThirdPartyPolicyExpiryDateTime": inputs.existingTPExpDate
                }
            },
            persons: [
              {
                personType: "INDIVIDUAL",
                addresses: [
                  {
                    addressType: "PRIMARY_RESIDENCE",
                    flatNumber: null,
                    streetNumber: null,
                    street: "",
                    district: "",
                    state: "27",
                    city: "Pune",
                    country: "IN",
                    pincode: "411001"
                  }
                ],

                communications: [
                  {
                    communicationType: "MOBILE",
                    "communicationId": step1Values.mobile, // mobile num
                    // communicationId: "9381624403", // mobile num
                    isPrefferedCommunication: true
                  },
                  {
                    communicationType: "EMAIL",
                    communicationId: step1Values.email, // email
                    isPrefferedCommunication: true
                  }
                ],
                identificationDocuments: [
                  {
                    identificationDocumentId: "",
                    documentType: "AADHAR_CARD",
                    documentId: "",
                    issuingAuthority: "",
                    issuingPlace: "",
                    issueDate: "2019-11-22",
                    expiryDate: "2019-11-22"
                  }
                ],
                isPolicyHolder: true,
                isVehicleOwner: true,
                firstName: step1Values.firstName, // first name
                middleName: null,
                lastName: step1Values.lastName, // last name
                // dateOfBirth: "1991-01-01", // dob
                "dateOfBirth": dateOfBirth&&dateOfBirth.split('/').reverse().join('-'), // dob **
                gender: step2Values.gender.toUpperCase(), // gender
                isDriver: true,
                isInsuredPerson: true
              }
            ],
            nominee: {
              firstName: step2Values.nomineeName, // nominee name
              middleName: "",
              lastName: "",
              dateOfBirth: step2Values.nomineedateOfBirth, // dob
              relation: step2Values.relation, // relation
              personType: "INDIVIDUAL"
            },
            pospInfo: {
              isPOSP: false,
              pospName: "",
              pospUniqueNumber: "",
              pospLocation: "",
              pospPanNumber: "",
              pospAadhaarNumber: "",
              pospContactNumber: ""
            },
            dealer: {
              dealerName: "",
              city: "",
              deliveryDate: null
            },
            motorQuestions: {
              furtherAgreement: "",
              selfInspection: false,
              financer: ""
            },
            kyc: {
              isKYCDone: godigitKycDetails.registered == "yes" ? true : false,

              ckycReferenceDocId:
                godigitKycDetails.registered == "yes" ? "D02" : "D07",

              ckycReferenceNumber: godigitKycDetails.registered == "yes" ? godigitKycDetails.ckycNo:godigitKycDetails.pancardNo&&godigitKycDetails.pancardNo.toUpperCase(),

              dateOfBirth: godigitKycDetails.dob,

              photo: ""
            },
          },
        
          {
            headers: {
              authorization: jwtToken,
              "Content-Type": "application/json",
              accept: "application/json",
              "x-rsa-type": 2
            }
          }
        )
        .then((res) => {
          // setApiData(res);
          setProposalApiData(res.data);
          seTisDataLoaded(true);

          localStorage.setItem("bike_payment_Data", JSON.stringify(res.data));
          localStorage.setItem("proposal_loaded", true);
          dispatch(carProposalAction.setApiFailed(false));

        })

        .catch((error) => {
          dispatch(carProposalAction.setApiFailed(true));

          console.log(error)
        });


           /* ---------------- Proposal For RSA ------------------ */

           proceed&&   tileType == "RSA"  &&
           axios
             .post(
               `${API_ENDPOINT}/proposal/rsa/2w`,
               
         
               
           
          


            {
              "quoteId" : popData&&popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.QUOTE_ID,
              "premium" :+(popData&& popData.data.rsaQuoteResponse).PREMIUMDETAILS.DATA.GROSS_PREMIUM,
              "proposerDetails" : {
                
                "title" : step1Values.salutation,
                "firstName" : step1Values.firstName,
                "lastName" :  step1Values.lastName,
                "emailId" : step1Values.email,
                "cKycNumber" :rsaKycApiData&&rsaKycApiData.ckycNo,
                "mobileNo" : step1Values.mobile,
                "dateOfBirth" :dateOfBirth&&dateOfBirth.split('/').reverse().join('/'),
                "occupation" : "Others",
                "nomineeName" : step2Values.nomineeName,
                "nomineeAge" : `${step2Values.age}`,
                "relationshipWithNominee": step2Values.relation,
                 "permanentAddress1":step3Values.address,
                 "permanentAddress2": step3Values.address2,
                 "permanentCity": rsaKycApiData&&rsaKycApiData.city,
                 "permanentPincode":step3Values.pincode,
                 "sameAdressReg": "Yes",
                 "ResidenceAddressOne":step3Values.address,
                 "ResidenceAddressTwo": step3Values.address2,
                 "ResidenceCity":   rsaKycApiData&&rsaKycApiData.city,
                 "ResidencePinCode":step3Values.pincode,
                 "panNumber": rsaKycApiData&&rsaKycApiData.panNumber,  //pan card Number
              "validatePan": "Yes",
              },
             
              "vehicleDetails" : {
                "engineNumber":  inputs.engineNo,
                "chassisNumber": inputs.chasisNo,
                "isTwoWheelerFinanced" :inputs.loan == "yes"?"Yes":"No",
                "vehicleSubLine" : "motorCycle",
                "rtoName" :  modelRegionId.rsa.rtoName,
                "vehicleModelCode" : masterID.rsaModelCode[0],
                "planOpted" : "Flexi Plan",
                "yearOfManufacture" :manufactureYear,
                "voluntaryDeductible" :  inputDiscount == ("" || null || undefined)
                ? 0
                :(( (popUpsFilterData==undefined||null))?initialPolicyType=="thirdParty":popUpsFilterData=="thirdParty")
                ?0
                : inputDiscount == "None"
                ?0
                : inputDiscount,
                "vehicleManufacturerName" : "Bajaj",
                "idv" : 0.0,
                "discountIdvPercent" : 0,
                "modifiedIdv" : idvValue == ("" || null || undefined)
                ? "0"
                :  (( (popUpsFilterData==undefined||null))?initialPolicyType.policy=="thirdParty":popUpsFilterData=="thirdParty")
                ? "0"
                : idvValue,
                "vehicleMostlyDrivenOn" : "City roads",
                "vehicleRegDate" : inputs.bikeRegistrationDate.split('-').reverse().join('/'),
                "vehicleRegisteredInTheNameOf" : "Individual",
                "modelName" : "",
                "previousPolicyType":'comprehensive',
            
                "registrationNumber" : inputs.bikeRegisterNo.toUpperCase(),
                "isPreviousPolicyHolder" : "false",
                "productName" :['true',true].includes(isBikeRegistered)  ?"RolloverTwowheeler":"BrandNewTwowheeler",
                "carRegisteredCity" : "",
                "isProductCheck" : "true",
                "personalAccidentCoverForUnnamedPassengers":finalAddons == ("" || null || undefined)
                ? ''
                : finalAddons.unnamedpassengerPAcover
                ? "100000"
                : "0",
                "accidentCoverForPaidDriver":finalAddons == ("" || null || undefined)
                ? ''
                : finalAddons.paidDrivercover
                ? '100000'
                : '0',
              "tppdLimit":tppdChange? 6000:null,
                
                "legalliabilityToPaidDriver" : "No",
                "legalliabilityToEmployees" : "No",
                "claimsMadeInPreviousPolicy" : prevClaimValue == "Yes" ? "Yes" : "No",
                "noClaimBonusPercent" : "",
                "ncbcurrent" : "",
                "claimAmountReceived" : "0",
                "claimsReported" : "0",
                "ncbprevious" : ((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?'0':`${registeredModelValues&&+registeredModelValues.prevNCB}`,
                "vechileOwnerShipChanged" :  tileType == "RSA"&& ownership=='Yes'?'Yes': "No",
                "typeOfCover" :  ['true',true].includes(isBikeRegistered) ? (productCodeValue
                  ? popUpsFilterData == "comprehensive" && "Comprehensive"
                  : initialPolicyType.policy == "comprehensive" && "Comprehensive") ||
                ((productCodeValue
                  ? popUpsFilterData == "thirdParty" && "LiabilityOnly"
                  : initialPolicyType.policy == "thirdParty") &&
                  "LiabilityOnly") ||
                (productCodeValue
                  ? popUpsFilterData == "ownDamage" && "standalone"
                  : initialPolicyType.policy == "ownDamage" && "standalone")
              : (productCodeValue
                  ? popUpsFilterData == "bundled" && "Bundled"
                  : initialPolicyType.policy == "bundled" && "Bundled") ||
                (productCodeValue
                  ? popUpsFilterData == "thirdParty" && "LiabilityOnly"
                  : initialPolicyType.policy == "thirdParty" && "LiabilityOnly"),
                "cpaPolicyTerm" : 0,
                "cpaCoverDetails" : {
                  "noEffectiveDrivingLicense" : false,
                  "cpaCoverWithInternalAgent" :  finalAddons && finalAddons.ownerDriverPAcover ? true : false,
                  "standalonePAPolicy" :  finalAddons && finalAddons.ownerDriverPAcover ? false : true
                },
                "cpaCoverisRequired" :finalAddons && finalAddons.ownerDriverPAcover ? "Yes" : 'No' ,
                "isTwoWheelerFinancedValue" : "Hypothecation",
                // "isTwoWheelerFinancedValue" : tileType == "RSA"&& inputs.financedValue?inputs.financedValue:null,
                "financierName" :  inputs.financierName?inputs.financierName:null,
                "previousPolicyNo" : inputs.prevPolicyNo,
                "previousInsurerName" : inputs.prevPolicyInsurer,
                "previousPolicyExpiryDate" :  prevActDay+ "/" + prevMonth + "/" +prevYear,
                "idvFor2Year" : 0,
                "discountIDVPercent2Year" : 0,
                "modifiedIDVFor2Year" : 0,
                "idvFor3Year" : 0,
                "validPUCAvailable" : "Yes",
                "pucnumber" : "",
                "pucvalidUpto" : "" // today to 6 months 
              },
              "regionCode" : null,
              "source" : "STP",
              "utmCampign" : null,
              "utmContent" : null,
              "utmMedium" : null,
              "utmSource" : null,
              "utmTerm" : null,
              "posCode" : null,
              "posDetails" : null,
              "posOpted" : false,
              "existingTPPolicyDetails" : {
                "tpInsurer" :inputs.prevPolicyInsurer,
                "tpPolicyNumber" : (
                  popUpsFilterData == (undefined || null)
                    ? initialPolicyType.policy == "ownDamage"
                    : popUpsFilterData == "ownDamage"
                )
                  ? step4Values.tpPolicyNumber
                  : inputs.prevPolicyNo,
                "tpInceptionDate" : `${tpDay}/${tpMonth}/${tpYear}`,
                "tpExpiryDate" : Object.keys(inputs).includes("existingTPExpDate") &&
                inputs.existingTPExpDate.split('-').reverse().join('/'),
                "tpPolicyTerm" : 3
              },
              "authenticationDetails" : {
                "apikey" : "310ZQmv/bYJMYrWQ1iYa7s43084=",
                "agentId" : "BA506423"
              },



            //  "quoteId": "AG043210VMCT76001727",

            
          //    "proposerDetails": {
          //        "title":'Mr',
          //        "firstName":step1Values.firstName,
          //        "lastName": step1Values.lastName,
          //        "emailId": step1Values.email,
          //        "userID": 0,
          //        "mobileNo": step1Values.mobile,
          //        "dateOfBirth":dateOfBirth&&dateOfBirth.split('/').reverse().join('/'),
          //        "occupation": "Others",
          //        "nomineeName":step2Values.nomineeName,
          //        "nomineeAge":step2Values.age,
          //        "relationshipWithNominee": step2Values.relation,
          //        "permanentAddress1":step3Values.address,
          //        "permanentAddress2": step3Values.address2,
          //        "permanentCity":pincodeDetails.cityOrVillageName,
          //        "permanentPincode":step3Values.pincode,
          //        "sameAdressReg": "Yes",
          //        "ResidenceAddressOne":step3Values.address,
          //        "ResidenceAddressTwo": step3Values.address2,
          //        "ResidenceCity":  pincodeDetails.cityOrVillageName,
          //        "ResidencePinCode":step3Values.pincode,
              
         
          //        "cKycNumber": rsaKycApiData&&rsaKycApiData.ckycNo,
          //        "kycDone": "",
          //        "eKycRefNumber": ""
          //    },
          //    "vehicleDetails": {
          //     "engineNumber":  inputs.engineNo,
          //     "chassisNumber": inputs.chasisNo,
          //     "isTwoWheelerFinanced": inputs.loan == "yes"?'Yes':"No",
          //     "vehicleSubLine": "motorCycle",
          //     "vehicleModelCode":  masterID.rsaModelCode[0], // TBC
          //     "planOpted": "Flexi Plan",
          //     "yearOfManufacture":manufactureYear,
          //     "drivingExperience": "1",
          //     "voluntaryDeductible":  inputDiscount == ("" || null || undefined)
          //     ? ""
          //     :(( (popUpsFilterData==undefined||null))?initialPolicyType=="thirdParty":popUpsFilterData=="thirdParty")
          //     ? ""
          //     : inputDiscount == "None"
          //     ? ""
          //     : inputDiscount,
          //     "isValidDrivingLicenseAvailable": "Yes",
          //     "hdnDepreciation":  finalAddons == null || undefined
          //     ? false
          //     : finalAddons.zeroDepreciation
          //     ? true
          //     :  false, // zero Depricia True : false
          //     "depreciationWaiver": finalAddons == null || undefined
          //     ?  "Off"
          //     : finalAddons.zeroDepreciation
          //     ? "On"
          //     :  "Off",
          //     "hdnEngineProtector": false,
          //     "coveredParkingOrGroundParking": "No",
          //     "coveredParkingNotBasement": "No",
          //     "unknownParking": "No",
          //     "hdnFullInvoice": false,
          //     "fullInvoicePlan1": "No",
          //     "fullInvoicePlan2": "No",
          //     "vehicleManufacturerName": "Bajaj",
          //     // "idv":popData&&popData.data.rsaQuoteResponse.PREMIUMDETAILS.DATA.IDV,
          //     "idv":0,
          //     "discountIdvPercent": 0,
          //     "modifiedIdv": 0.0,
          //     "vehicleMostlyDrivenOn": "City roads",
          //     "vehicleRegDate": inputs.bikeRegistrationDate.split('-').reverse().join('/'),
          //     "vehicleRegisteredInTheNameOf": "Individual",
          //     "modelName": "",
          //     // "previousPolicyType": "Comprehensive",

          //     "previousPolicyType":['true',true].includes(isBikeRegistered) ? (productCodeValue
          //       ? popUpsFilterData == "comprehensive" && "Comprehensive"
          //       : initialPolicyType.policy == "comprehensive" && "Comprehensive") ||
          //     ((productCodeValue
          //       ? popUpsFilterData == "thirdParty" && "LiabilityOnly"
          //       : initialPolicyType.policy == "thirdParty") &&
          //       "LiabilityOnly") ||
          //     (productCodeValue
          //       ? popUpsFilterData == "ownDamage" && "standalone"
          //       : initialPolicyType.policy == "ownDamage" && "standalone")
          //   : (productCodeValue
          //       ? popUpsFilterData == "bundled" && "Bundled"
          //       : initialPolicyType.policy == "bundled" && "Bundled") ||
          //     (productCodeValue
          //       ? popUpsFilterData == "thirdParty" && "LiabilityOnly"
          //       : initialPolicyType.policy == "thirdParty" && "LiabilityOnly"),
          //     "registrationNumber":  inputs.bikeRegisterNo,
          //     "isPreviousPolicyHolder": "false",
          //     "previousPolicyExpiryDate":  prevActDay+ "/" + prevMonth + "/" +prevYear ,
          //     "productName":['true',true].includes(isBikeRegistered)  ?"RolloverTwowheeler":"BrandNewTwowheeler",
          //     "typeOfCover": ['true',true].includes(isBikeRegistered) ? (productCodeValue
          //       ? popUpsFilterData == "comprehensive" && "Comprehensive"
          //       : initialPolicyType.policy == "comprehensive" && "Comprehensive") ||
          //     ((productCodeValue
          //       ? popUpsFilterData == "thirdParty" && "LiabilityOnly"
          //       : initialPolicyType.policy == "thirdParty") &&
          //       "LiabilityOnly") ||
          //     (productCodeValue
          //       ? popUpsFilterData == "ownDamage" && "standalone"
          //       : initialPolicyType.policy == "ownDamage" && "standalone")
          //   : (productCodeValue
          //       ? popUpsFilterData == "bundled" && "Bundled"
          //       : initialPolicyType.policy == "bundled" && "Bundled") ||
          //     (productCodeValue
          //       ? popUpsFilterData == "thirdParty" && "LiabilityOnly"
          //       : initialPolicyType.policy == "thirdParty" && "LiabilityOnly"),
          //     "fuelType": "Petrol",
          //     "region": "East Region",
          //     "carRegisteredCity": "",
          //     "isProductCheck": "true",
          //     "engineCapacityAmount": "",
          //     "personalAccidentCoverForUnnamedPassengers":finalAddons == ("" || null || undefined)
          //     ? ''
          //     : finalAddons.unnamedpassengerPAcover
          //     ? "100000"
          //     : "0",
          //     "accidentCoverForPaidDriver":finalAddons == ("" || null || undefined)
          //     ? ''
          //     : finalAddons.paidDrivercover
          //     ? '100000'
          //     : '0',
          //     "legalliabilityToPaidDriver": "No",
          //     "legalliabilityToEmployees": "No",
          //     "claimsMadeInPreviousPolicy": prevClaimValue == "Yes" ? "Yes" : "No",
          //     "noClaimBonusPercent": "",
          //     "ncbcurrent": "",
          //     "claimAmountReceived": "0",
          //     "claimsReported": "0",
          //     "ncbprevious":((registeredModelValues&&registeredModelValues.prevNCB==(null || undefined))||prevClaimValue=='Yes')?0:`${registeredModelValues&&+registeredModelValues.prevNCB}`,
          //     "vechileOwnerShipChanged": tileType == "RSA"&& ownership=='Yes'?'Yes': "No",
          //     "idvFor2Year": 0.0,
          //     "discountIDVPercent2Year": 0,
          //     "modifiedIDVfor2Year": 0.0,
          //     "idvFor3Year": 0.0,
          //     "discountIDVPercent3Year": 0,
          //     "modifiedIDVfor3Year": 0.0,
          //     "idvFor4Year": 0.0,
          //     "discountIDVPercent4Year": 0,
          //     "modifiedIDVfor4Year": 0.0,
          //     "idvFor5Year": 0.0,
          //     "discountIDVPercent5Year": 0,
          //     "modifiedIDVfor5Year": 0.0,
          //     "cpaPolicyTerm": 0,
          //     "cpaCoverisRequired": "Yes",
          //     "cpaCoverDetails": {
          //         "noEffectiveDrivingLicense": false,
          //         "cpaCoverWithInternalAgent": true,
          //         "standalonePAPolicy": false
          //     },
          //     "rtoName": modelRegionId.rsa.rtoName,
          //     "addlDiscount": 0.0,
          //     "validPUCAvailable": "Yes",
          //     "pucnumber": "",
          //     "pucvalidUpto": "29/12/2023",
          //     "addOnsOptedInPreviousPolicy": "",
          //     "financierName": inputs.financierName,
          //     "isTwoWheelerFinancedValue":tileType == "RSA"&& inputs.financedValue,
          //     "previousInsurerName":inputs.prevPolicyInsurer,
          //     "previousPolicyNo": inputs.prevPolicyNo
          // },
          // "posOpted": false,
          // "existingTPPolicyDetails": {
          //     "tpInsurer":  inputs.prevPolicyInsurer,
          //     "tpPolicyNumber":(
          //       popUpsFilterData == (undefined || null)
          //         ? initialPolicyType.policy == "ownDamage"
          //         : popUpsFilterData == "ownDamage"
          //     )
          //       ? step4Values.tpPolicyNumber
          //       : inputs.prevPolicyNo,
          //     "tpInceptionDate": `${tpDay}/${tpMonth}/${tpYear}`,
          //     "tpExpiryDate": Object.keys(inputs).includes("existingTPExpDate") &&
          //     inputs.existingTPExpDate.split('-').reverse().join('/'),
          //     "tpPolicyTerm": 2
          //    },
          //    "authenticationDetails": {
          //        "apikey": "310ZQmv/bYJMYrWQ1iYa7s43084=",
          //        "agentId": "AG043210"
          //    }
         },
                 
         
                 {
                   headers: {
                     authorization: jwtToken,
                     "Content-Type": "application/json",
                     accept: "application/json",
                     "x-rsa-type": 2
                   }
                 }
               )
               .then((res) => {
                 // setApiData(res);
                 setProposalApiData(res.data);
                 seTisDataLoaded(true);
         
                 localStorage.setItem("bike_payment_Data", JSON.stringify(res.data));
                 localStorage.setItem("proposal_loaded", true);
          dispatch(carProposalAction.setApiFailed(false));

               })
         
               .catch((error) => {
          dispatch(carProposalAction.setApiFailed(true));

                console.log(error)
              });
  };

 

  useEffect(() => {
    const inputValues = localStorage.getItem("bike_step-4_Values");
    inputValues && setInputs(JSON.parse(inputValues));
  }, []);

  useEffect(()=>{
    if (tileType=='Reliance'||tileType=='RSA'||tileType=='GoDigit'){
     setInputs({...inputs,
       bikeRegisterNo:isBikeRegistered=='true'? normalBikeRegNo:'NEW',
       bikeRegistrationDate:initialPolicyType.registrationDate
     })
    }
     
   },[initialPolicyType.registrationDate, normalBikeRegNo, tileType])

  useEffect(() => {
    const onStorage = () => {
      setJwtToken(localStorage.getItem("jwt_token"));
    };

    window.addEventListener("storage", onStorage);

    return () => {
      window.removeEventListener("storage", onStorage);
    };
  }, []);

  // console.log(
  //   "testing ",
  //   popUpsFilterData == (undefined || null)
  //     ? initialPolicyType.policy == "ownDamage"
  //     : popUpsFilterData == "ownDamage",
  //   initialPolicyType.policy == "ownDamage",
  //   popUpsFilterData
  // );

  useEffect(() => {
    localStorage.setItem("proposal_loaded", false);
  }, []);



  useEffect(() => {
    if (
    
      isBikeRegistered == ("true" || true)
      ? Object.values(inputs).length >= (Object.keys(inputs).includes('loan')&&inputs.loan == "yes"?   ((isBikeRegistered == "true" || isBikeRegistered == true)&&(popUpsFilterData == (undefined || null)
     ? initialPolicyType.policy == "ownDamage"
     : popUpsFilterData == "ownDamage")? tileType == "RSA"? 14:14 : tileType == "RSA"?11:11) // for od left ;; for oth right 
     ///if Loan yes above value taken 
     //if loan no below value taken
     :  ((isBikeRegistered == "true" || isBikeRegistered == true)&&(popUpsFilterData == (undefined || null)
     ? initialPolicyType.policy == "ownDamage"
     : popUpsFilterData == "ownDamage"))?tileType == "RSA"?11: 11:tileType == "RSA"?8: 8) &&
         inputs.bikeRegisterNo.length > 1 &&
         inputs.engineNo.length > 1 &&
         inputs.chasisNo.length > 1 &&
         // (tileType == "RSA"?inputs.vehicleOwnership.length > 1 :true) &&
         inputs.bikeRegistrationDate.length > 1 &&
         inputs.prevPolicyNo.length > 1 &&
         inputs.prevPolicyInsurer.length > 1 &&
         ((popUpsFilterData == (undefined || null)
         ? initialPolicyType.policy == "ownDamage"
         : popUpsFilterData == "ownDamage") ?
         ((Object.keys(inputs).includes('tpPolicyNumber') &&inputs.tpPolicyNumber.length > 1) && (Object.keys(inputs).includes('existingTPExpDate')&&inputs.existingTPExpDate.length > 1)&&(Object.keys(inputs).includes('prevTpInsurer') &&inputs.prevTpInsurer.length > 1)):true) &&
         inputs.loan.length > 1&&
         (Object.keys(inputs).includes("financierName")
           ? inputs.financierName.length > 1
           : true) &&
        //  (Object.keys(inputs).includes("financedValue")
        //    ? inputs.financierName.length > 1
        //    : true)
            // &&
         (Object.keys(inputs).includes("financierCity")
           ? inputs.financierCity.length > 1
           : true) &&
         (Object.keys(inputs).includes("financierAddress")
           ? inputs.financierAddress.length > 1
           : true)
     : // inputs.externalKit.length > 1
       Object.values(inputs).length >= ( (Object.keys(inputs).includes('loan')&&inputs.loan == "yes")?tileType == "RSA"?9: 8 :tileType == "RSA"?6: 5) &&
       inputs.bikeRegisterNo.length > 1 &&
         // (tileType == "RSA"?inputs.vehicleOwnership.length > 1:true) &&
         inputs.engineNo.length > 1 &&
       inputs.chasisNo.length > 1 &&
       inputs.bikeRegistrationDate.length > 1 &&
       (Object.keys(inputs).includes("financierName")
         ? inputs.financierName.length > 1
         : true) &&
      //  (Object.keys(inputs).includes("financedValue")
      //    ? inputs.financierName.length > 1
      //    : true) &&
       (Object.keys(inputs).includes("financierCity")
         ? inputs.financierCity.length > 1
         : true) &&
       (Object.keys(inputs).includes("financierAddress")
         ? inputs.financierAddress.length > 1
         : true) &&
       inputs.loan.length > 1
        // inputs.externalKit.length > 1
    ) {
      setProceed(true);
      localStorage.setItem("bike_step", 4);
      localStorage.setItem("bike_step-4_Values", JSON.stringify(inputs));
    } else {
      setProceed(false);
    }
    // if (Object.values(inputs).length >= (inputs.loan == "yes" ? 8 : 5)) {
    //   setProceed(true);
    //   localStorage.setItem("step", 4);
    //   localStorage.setItem("step-4_Values", JSON.stringify(inputs));
    // }
  }, [initialPolicyType, inputs,tileType, isBikeRegistered, popUpsFilterData]);

  useEffect(() => {
    if (/^[a-zA-Z0-9]*$/.test(inputs.bikeRegisterNo) == false) {
      setBikeRegisterNoError(true);
    }
    if (/^[a-zA-Z0-9]*$/.test(inputs.engineNo) == false) {
      setEngineNoError(true);
    }
    if (/^[a-zA-Z0-9]*$/.test(inputs.chasisNo) == false) {
      setChasisNoError(true);
    }
    if (/^[a-zA-Z0-9]*$/.test(inputs.tpPolicyNumber) == false) {
      setTpPolicyNumberError(true);
    }
    if (/^[a-zA-Z0-9- /]*$/.test(inputs.prevPolicyNo) == false) {
      setPrevPolicyNoError(true);
    }
    if (/^[a-zA-Z- /]*$/.test(inputs.financierName) == false) {
      inputs.loan == "yes" && setFinancierNameError(true);
    }
  }, [
    inputs,
    inputs.bikeRegisterNo,
    inputs.engineNo,
    inputs.tpPolicyNumber,
    inputs.chasisNo,
    inputs.prevPolicyNo,
    inputs.financierName,
    bikeRegisterNoError,
    chasisNoError,
    prevPolicyNoError,
    financierNameError,
    proceed
  ]);

 

  return (
    <div>
      <>
        <NavHeader dropClose={[closeNav, setCloseNav]} />
        <main
          className={`lg:w-full overflow-x-hidden  lg:pt-16 bg-no-repeat bg-cover  ${
            isPopWindowOpen ? "h-screen " : ""
          }   `}
          style={{ backgroundImage: `url(${LandingBackground})` }}
          onClick={() => {
            setCloseNav(false);
          }}
        >
          <BikeInsuranceHeader
            popwindow={[isPopWindowOpen, setIsPopWindowOpen]}
          />
          <div className="pt-5 lg:pt-10 pb-16 lg:pb-24">
            <BikeInsuranceTracker />
          </div>
          <form
            onChange={handleChange}
            className={`${isPopWindowOpen ? "hidden" : ""}`}
          >
            <div className="slider-in justify-center overflow-x-hidden bg-white mx-5 lg:m-auto drop-shadow-lg lg:w-[55%]    rounded-xl p-4 ">
              <div className="flex items-center space-x-2 pb-4">
                <div className="w-6 h-6 lg:w-8 lg:h-8 bg-hero text-white rounded-full items-center flex justify-center">
                  4
                </div>
                <h1 className="m  text-solid lg:text-xl  font-semibold">
                  Vehicle Details
                </h1>
              </div>
              <hr className="text-[#DBDBDB] pb-4" />
              <div className="">
                <InputPP
                  className="w-full lg:w-96 border uppercase border-[#d7d5d5] rounded-md  px-3 py-2 my-2"
                  parenetClassName="lg:w-max"
                  labelClassName="text-[#959595] font-medium pl-1 w-20"
                  id="bikeRegisterNo"
                  label="Bike Registration Number"
                  type="text"
                  placeholder="eg. AP01 XXXX"
                  value={isBikeRegistered=='true'? normalBikeRegNo:'NEW'}
                  disableTrue={true}

                  errorState={[bikeRegisterNoError, setBikeRegisterNoError]}
                  // state={[ownerName, setOwnerName]}
                />
                <div className="flex flex-col lg:flex-row lg:space-x-5 py-3">
                  <InputPP
                    className="w-full lg:w-96 border border-[#d7d5d5] rounded-md  px-3 py-2 my-2"
                    parenetClassName="xk:w-max"
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    id="engineNo"
                    label="Engine Number"
                    type="text"
                    
                    maxlengths="17"
                    placeholder="Enter Engine Number"
                    value={inputs.engineNo}
                    errorState={[engineNoError, setEngineNoError]}
                    // state={[ownerName, setOwnerName]}
                  />
                  <InputPP
                    className="w-full xl:w-96 border border-[#d7d5d5] rounded-md  px-3 py-2 my-2"
                    parenetClassName="lg:w-max"
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    id="chasisNo"
                    label="Chasis Number"
                    type="text"
                    maxlengths="17"
                    placeholder="Enter Chasis Number "
                    value={inputs.chasisNo}
                    errorState={[chasisNoError, setChasisNoError]}
                    // state={[ownerName, setOwnerName]}
                  />
                </div>
                <InputPP
                  className="w-full date lg:w-96 border border-[#d7d5d5] rounded-md  px-3 py-2 my-2"
                  parenetClassName="lg:w-max"
                  labelClassName="text-[#959595] font-medium pl-1 w-20"
                  id="bikeRegistrationDate"
                  label="Bike Registration Date"
                  type="date"
                  disableTrue={ true}

                  placeholder=""
                  value={initialPolicyType.registrationDate}

                  errorState={[bikeRegisterDateError, setBikeRegisterDateError]}
                  // state={[ownerName, setOwnerName]}
                />
                <div className=" my-4 ">
                  <DropDownPP
                    className="w-full  lg:w-96 border border-[#d7d5d5] rounded-md  px-3 py-2.5 my-2"
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    parentClassName={`${
                      isBikeRegistered == "true" || isBikeRegistered == true
                        ? ""
                        : "hidden"
                    }`}
                    label="Previous Policy Insurer "
                    id="prevPolicyInsurer"
                    differentDrop={false}
                    options={prevInsurerData2?prevInsurerData2:[]}
                    value={inputs.prevPolicyInsurer}
                    errorState={[
                      prevPolicyInsurerError,
                      setPrevPolicyInsurerError
                    ]}
                    special={true}
                  />
                </div>
                <InputPP
                  className={`  w-full lg:w-96 border border-[#d7d5d5] rounded-md  px-3 py-2 my-2`}
                  parenetClassName={`${
                    isBikeRegistered == "true" || isBikeRegistered == true
                      ? ""
                      : "hidden"
                  }`}
                  labelClassName="text-[#959595] font-medium pl-1"
                  id="prevPolicyNo"
                  label="Previous Policy Number"
                  type="text"
                  placeholder="Policy number"
                  value={inputs.prevPolicyNo}
                  errorState={[prevPolicyNoError, setPrevPolicyNoError]}
                  // state={[emailId, setEmailId]}
                />
                <div className="">
                  <InputPP
                    className="w-full date lg:w-96 border border-[#d7d5d5] rounded-md  px-3 py-2 my-"
                    labelClassName="text-[#959595] font-medium pl-1"
                    parenetClassName={`${
                      (isBikeRegistered == "true" || isBikeRegistered == true)&&(popUpsFilterData == (undefined || null)
                      ? initialPolicyType.policy == "ownDamage"
                      : popUpsFilterData == "ownDamage")
                        ? ""
                        : "hidden"
                    }  my-3`}
                    id="existingTPExpDate"
                    label="Existing TP Expiry Date"
                    type="date"
                    placeholder=""
                    value={inputs.existingTPExpDate}
                    errorState={[exisTPExpDateError, setExisTPExpDateError]}
                    // state={[emailId, setEmailId]}
                  />
                </div>

                <InputPP
                  className={`  w-full lg:w-96 border border-[#d7d5d5] rounded-md  px-3 py-2 my-2`}
                  parenetClassName={`${
                    (isBikeRegistered == "true" || isBikeRegistered == true)&&(popUpsFilterData == (undefined || null)
                    ? initialPolicyType.policy == "ownDamage"
                    : popUpsFilterData == "ownDamage")
                      ? ""
                      : "hidden"
                  }`}
                  labelClassName="text-[#959595] font-medium pl-1"
                  id="tpPolicyNumber"
                  label="Tp Policy Number"
                  type="text"
                  placeholder="Tp Policy Number"
                  value={inputs.tpPolicyNumber}
                  errorState={[tpPolicyNumberError, setTpPolicyNumberError]}
                  // state={[emailId, setEmailId]}
                />
            <div className={`${
                      (isBikeRegistered == "true" || isBikeRegistered == true)&&(popUpsFilterData == (undefined || null)
                      ? initialPolicyType.policy == "ownDamage"
                      : popUpsFilterData == "ownDamage")
                        ? ""
                        : "hidden"
                    } `}>
<DropDownPP
                    className="w-full  lg:w-96 border border-[#d7d5d5] rounded-md  px-3 py-2.5 my-2"
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    
                    label="Previous TP insurer"
                    id="prevTpInsurer"
                    differentDrop={false}
                    options={prevInsurerData2?prevInsurerData2:[]}
                    value={inputs.prevTpInsurer}
                    errorState={[
                      prevTpInsurerError,
                      setPrevTpInsurerError
                    ]}
                    special={true}
                  />
</div>    
                <div className="py-4 space-y-5 lg:space-y-3">
                  <RadioPP
                    title="Is your Bike currently on loan?"
                    value={inputs.loan}
                    errorState={[bikeLoanError, setBikeLoanError]}
                    objValues={[
                      {
                        id: "yes",
                        name: "loan",
                        value: "Yes"
                      },
                      {
                        id: "no",
                        name: "loan",
                        value: "No"
                      }
                    ]}
                  />
                  <InputPP
                    className="w-full lg:w-96 border border-[#d7d5d5] rounded-md  px-3 py-2 my-2"
                    parenetClassName={`${
                      inputs.loan == "yes" ? "" : "hidden"
                    } lg:w-max`}
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    id="financierName"
                    label="Financier Name"
                    type="text"
                    placeholder="Financier Name"
                    value={inputs.financierName}
                    errorState={[financierNameError, setFinancierNameError]}
                    // state={[ownerName, setOwnerName]}
                  />
                     {/* <InputPP
                    className="w-full lg:w-96 border border-[#d7d5d5] rounded-md  px-3 py-2 my-2"
                    parenetClassName={`${
                      inputs.loan == "yes" &&tileType == "RSA" ? "" : "hidden"
                    }  lg:w-max`}
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    id="financedValue"
                    label="Financed Value?"
                    type="text"
                    placeholder="Financed Value"
                    value={inputs.financedValue}
                    errorState={[financedValue, setFinancedValue]}
                    // state={[ownerName, setOwnerName]}
                  /> */}
                  <InputPP
                    className="w-full lg:w-96 border border-[#d7d5d5] rounded-md  px-3 py-2 my-2"
                    parenetClassName={`${
                      inputs.loan == "yes" ? "" : "hidden"
                    } lg:w-max`}
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    id="financiercity"
                    label="Financier City"
                    type="text"
                    placeholder="City"
                    value={inputs.financiercity}
                    errorState={[financierCityError, setFinancierCityError]}
                    // state={[ownerName, setOwnerName]}
                  />
                  <InputPP
                    className="w-full lg:w-96 border border-[#d7d5d5] rounded-md  px-3 py-2 my-2"
                    parenetClassName={`${
                      inputs.loan == "yes" ? "" : "hidden"
                    } lg:w-max`}
                    labelClassName="text-[#959595] font-medium pl-1 w-20"
                    id="financierAddress"
                    label="Financier Address"
                    type="text"
                    placeholder="Address"
                    value={inputs.financierAddress}
                    errorState={[financierAddress, setFinancierAddress]}
                    // state={[ownerName, setOwnerName]}
                  />

<div className={`${tileType == "RSA"?'':'hidden'}`}>


{/* <RadioPP
  title="Has the vehicle ownership changed in last 12 months?"
  value={inputs.vehicleOwnership}
  errorState={[vehicleOwnershipError, setvehicleOwnershipError]}
  objValues={[
    {
      id: "Yes",
      name: "vehicleOwnership",
      value: "Yes"
    },
    {
      id: "No",
      name: "vehicleOwnership",
      value: "No"
    }
  ]}
/> */}

</div>
                  {/* <RadioPP
                    title="Does this bike have an External CNG/LPG Kit ? "
                    value={inputs.externalKit}
                    errorState={[extKitError, setExtKitError]}
                    objValues={[
                      {
                        id: "externalKityes",
                        name: "externalKit",
                        value: "Yes"
                      },
                      {
                        id: "externalKitno",
                        name: "externalKit",
                        value: "No"
                      }
                    ]}
                  /> */}
                </div>
              </div>
            </div>
            <div className="flex justify-center space-x-5 py-8 m-auto  w-[53%] ">
              <Link to="/bikeinsurance/bike-profile/step-3">
                <button className="bg-[#293651] hidden lg:block w-24 py-2 rounded-full text-white">
                  Back
                </button>
              </Link>
              <div className="flex flex-1"></div>
              {/* <Link to="/bikeinsurance/bike-profile/step-1"> */}
              <input
                type="submit"
                className="bg-hero w-max px-5 py-2 rounded-full text-white focus:outline-none cursor-pointer "
                onClick={handleSubmit}
                value="Review and Make Payment"
              />
              {/* </Link> */}
            </div>
          </form>
        </main>
      </>
    </div>
  );
};

export default BikeInfoStep4;





