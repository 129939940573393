import { createSlice } from "@reduxjs/toolkit";

const termInsuranceSlice = createSlice({
    name:'termInsurance',
    initialState:{
        
    firstName:'',
    age:'',
    gender:"",
    income:''
    },
    reducers: {
        updateField(state, action) {
          state[action.payload.field] = action.payload.value;
          localStorage.setItem('termInsurance', JSON.stringify(state));
        },
        loadFromLocalStorage(state) {
          const storedState = JSON.parse(localStorage.getItem('termInsurance'));
          if (storedState) {
            state[storedState.field] = storedState.value;
          }
          return state;
        }
      }
})

export default termInsuranceSlice.reducer;
export const { updateField } = termInsuranceSlice.actions