import React, { useState, useEffect } from "react";
import LandingBackground from "../../../assets/Svg/LandingPageBck.svg";
import MaleSvg from '../../../assets/Vector/Health/male.svg'
import FemaleSvg from '../../../assets/Vector/Health/female.svg'
import { Link, useNavigate } from "react-router-dom";
import NavHeader from "../../NavHeader";
import FormBar from "../../FormBar";
import RadioPP from "../../customs/RadioPP";
import InputPP from "../../customs/InputPP";
import { useDispatch } from "react-redux";
import { updateField } from "../../../store/slices/term-insurance-slice";
import { ageCalculator } from "../../../utils/dateUtils";

const TermIntro1 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [inputs, setInputs] = useState({
    gender:'',
    dob:'',
    smoke:'',
    pincode:''
  });
  const [errors, setErrors] = useState({});
  const [closeNav, setCloseNav] = useState(false);
  const [selectedGender, setSelectedGender] = useState('');
  const [showError, setShowError] = useState(false);

  const termStep1Values = localStorage.getItem('tm_step_1');

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({
      ...values,
      [name]: name === "gender" || name === "smoke" ? e.target.id : value
    }));
  };

  const validateInputs = () => {
    const newErrors = {};

    if (!inputs.gender) newErrors.gender = "Gender is required";
    if (!inputs.dob) newErrors.dob = "Date of birth is required";
    if (!inputs.smoke) newErrors.smoke = "Please indicate if you smoke or chew tobacco";
    if (!inputs.pincode || inputs.pincode.length !== 6) {
      newErrors.pincode = "Pincode must be 6 digits";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateInputs()) {
      setShowError(false);
      localStorage.setItem('tm_step_1', JSON.stringify(inputs));
      navigate("/terminsurance/term-profile/prestep-2");
      dispatch(updateField({ field: 'age', value: (inputs.dob) }));
      dispatch(updateField({ field: 'gender', value: (inputs.gender) }));
    } else {
      setShowError(true);
    }
  };


  useEffect(() => {
    if (termStep1Values) {
      setInputs(JSON.parse(termStep1Values));
      

    }
  }, [termStep1Values]);
 

 

  return (
    <>
      <NavHeader dropClose={[closeNav, setCloseNav]} />
      <main
        className="w-full pb-10 lg:pt-5s bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${LandingBackground})` }}
        onClick={() => setCloseNav(false)}
      >
        <form onSubmit={handleSubmit} onChange={handleChange}>
          <h1 className="text-xl lg:text-3xl text-[#293651] font-semibold py-6  text-center">
            Term Insurance Quote
          </h1>
          <div className="lg:w-[55%] rounded-t-md bg-[#aab8aa27] m-auto">
            <div className="lg:w-[33%] rounded-t-md bg-hero py-[3px]"></div>
          </div>

          <div className="justify-center bg-white mx-5 lg:m-auto shadow-[0px_0px_25px_rgba(0,0,0,0.1)] lg:drop-shadow-lg lg:w-[55%] h- [500px] px-4 py-5">
            <h1 className="mr-auto font-medium text-solid lg:text-xl pb-4 pl-4">
              Tell us about the Insured
            </h1>
            <hr className="text-[#DBDBDB] pb-" />

              <div className={`${Object.values(errors).length>0 ?'space-y-3':'space-y-5'}  py-4`}>
                <div className="">

                <RadioPP
                  title="Gender"
                  value={inputs.gender}
                  objValues={[
                    { id: "male", name: "gender", value: "Male" },
                    { id: "female", name: "gender", value: "Female" }
                  ]}
                  />
                {errors.gender && <p className="text-[#fc573d] text-sm pt-2">{errors.gender}</p>}
                  </div>

                  <div className="">
                    
                <InputPP
                  className="w-full date lg:w-96 border border-[#d7d5d5] rounded-md px-3 py-2 mt-2 "
                  parenetClassName="w-full"
                  labelClassName="text-[#959595] font-medium pl-1 w-20 "
                  id="dob"
                  value={inputs.dob}
                  label="Date of Birth"
                  type="date"
                  placeholder="Day"
                  />
                {errors.dob && <p className="text-[#fc573d] text-sm pt-2">{errors.dob}</p>}
                  </div>
                  <div className="">


                <RadioPP
                  title="Smoke or Chew Tobacco"
                  value={inputs.smoke}
                  objValues={[
                    { id: "yes", name: "smoke", value: "Yes" },
                    { id: "no", name: "smoke", value: "No" }
                  ]}
                  />
                {errors.smoke && <p className="text-[#fc573d] text-sm pt-2">{errors.smoke}</p>}
                  </div>
                  <div className="">

                <InputPP
                  className="w-full date lg:w-96 border border-[#d7d5d5] rounded-md px-3 py-2 mt-2 "
                  parenetClassName="w-full"
                  labelClassName="text-[#959595] font-medium pl-1 w-20"
                  id="pincode"
                  value={inputs.pincode}
                  label="Pincode"
                  type="number"
                  placeholder="Enter your Pincode"
                />
                {errors.pincode && <p className="text-[#fc573d] text-sm pt-2">{errors.pincode}</p>}
              </div>
              </div>

            {showError && (
              <h1 className="text-center pt-2 text-sm text-[#eb5648]">
                Please correct the errors above
              </h1>
            )}
          </div>

          <div className="flex justify-center space-x-5 pt-8">
            <Link to="/terminsurance">
              <button className="bg-[#293651] w-24 py-2 rounded-full text-white">
                Back
              </button>
            </Link>
            <input
              type="submit"
              className="bg-hero w-24 py-2 rounded-full text-white cursor-pointer"
              value="Next"
            />
          </div>
        </form>
      </main>
    </>
  );
};

export default TermIntro1;
