import { configureStore } from "@reduxjs/toolkit";
import uiSlice from "./slices/ui-slice";
import quoteSliceRsa from "./slices/quote-slice-rsa";
import quoteSliceGodigit from "./slices/quote-slice-godigit";
import quoteSliceReliance from "./slices/quote-slice-reliance";
 import carProposal from "./slices/car-proposal";
import bike_quoteSliceRsa from "./slices/bike_quote-slice-rsa";
import bike_quoteSliceGodigit from "./slices/bike_quote-slice-godigit";
import bike_quoteSliceReliance from "./slices/bike_quote-slice-reliance";
import bikeProposal from "./slices/bike-proposal";
import termInsurance from './slices/term-insurance-slice'
// import cartSlice from "./slices/cart-slice";

const store = configureStore({
    reducer:{
        ui:uiSlice,
        quoteRsa:quoteSliceRsa,
        quoteGodigit:quoteSliceGodigit,
        quoteReliance : quoteSliceReliance,
        carProposal:carProposal,
        bikeQuoteRsa:bike_quoteSliceRsa,
        bikeQuoteGodigit:bike_quoteSliceGodigit,
        bikeQuoteReliance : bike_quoteSliceReliance,
        bikeProposal:bikeProposal,
        termInsurace:termInsurance
        // cart:cartSlice
    }
})

export default store;