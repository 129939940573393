import React, { useState } from 'react';
import DropDownPP from '../customs/DropDownPP';

const TermAddonLayout = () => {
  const fields = [
    'Sum Assured',
    'Cover Upto Age',
    'Payment Frequency',
    'Payment Mode',
    'Income Term',
    'Insurers',
    'Return Of Premium',
  ];

  const [inputs, setInputs] = useState({
    sumAssured: '100000', // default value
    coverUptoAge: '60', // default value
    paymentFrequency: 'Monthly', // default value
    paymentMode: 'Cash', // default value
    incomeTerm: '10 years', // default value
    insurers: 'Insurer 1', // default value
    returnOfPremium: 'Yes', // default value
  });

  const [errors, setErrors] = useState({
    sumAssured: '',
    coverUptoAge: '',
    paymentFrequency: '',
    paymentMode: '',
    incomeTerm: '',
    insurers: '',
    returnOfPremium: '',
  });

  const getLabel = (field) => {
    return field.charAt(0).toUpperCase() + field.slice(1);
  };

  const getCamelCase = (field) => {
    return field.replace(/ /g, '').toLowerCase();
  };

  const getOptions = (field) => {
    // implement logic to return options based on field
    // for example, let's assume we have the following options for each field
    switch (field) {
      case 'Sum Assured':
        return ['100000', '200000', '300000'];
      case 'Cover Upto Age':
        return ['60', '70', '80'];
      case 'Payment Frequency':
        return ['Monthly', 'Quarterly', 'Yearly'];
      case 'Payment Mode':
        return ['Cash', 'Credit Card', 'Debit Card'];
      case 'Income Term':
        return ['10 years', '20 years', '30 years'];
      case 'Insurers':
        return ['Insurer 1', 'Insurer 2', 'Insurer 3'];
      case 'Return Of Premium':
        return ['Yes', 'No'];
      default:
        return [];
    }
  };

  const handleInputChange = (field, value) => {
    setInputs((prevInputs) => ({ ...prevInputs, [getCamelCase(field)]: value }));
  };

  return (
    <section className="bg-white relative my-3 p-4 shadow-[8px_8px_36px_rgba(0,167,142,0.18)] rounded-lg mb-6">
      <h1 className="font-semibold text-[#777777] pb-3    ">
        Customize your policy
      </h1>
      <div className="">
        {fields.map((field, index) => (
          <DropDownPP
            key={index}
            className="w-full  text-sm border border-[#d7d5d5] rounded-md focus:outline-none px-3 py-1.5 my-2"
            labelClassName="text-[#959595] font-medium pl-1 w-20 text-sm"
            label={getLabel(field)}
            id={field}
            value={inputs[getCamelCase(field)]}
            onChange={(value) => handleInputChange(field, value)}
            options={getOptions(field)}
            errorMessage={errors[getCamelCase(field)]}
          />
        ))}
      </div>
    </section>
  );
};

export default TermAddonLayout;